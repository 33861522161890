<template>
  <a-config-provider :theme="Theme">
    <div class="df-dropdown-button-wrapper">
      <!-- 不加ClientOnly，该组件在SSR时会报错 -->
      <ClientOnly>
        <a-dropdown-button
          class="df-dropdown-button w-full"
          :overlay-style="{
            boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.10), 0px 16px 32px 0px rgba(0,0,0,0.10)',
            borderRadius: '6px',
            width: `${w}px`,
          }"
          v-bind="$attrs"
          :size="size"
          :type="type"
        >
          <template v-for="slotName in Object.keys($slots)" #[slotName]>
            <slot :name="slotName"></slot>
          </template>
        </a-dropdown-button>
      </ClientOnly>
    </div>
  </a-config-provider>
</template>

<script setup lang="ts">
// import type { SizeType } from 'ant-design-vue/es/config-provider';
import type { ButtonType } from 'ant-design-vue/es/button';

defineOptions({
  inheritAttrs: false,
});

type PropType = {
  type?: ButtonType | 'highlight' | any;
  size?: 'large' | 'small' | 'middle' | any;
  triggerWidth?: string;
  popoverClass?: string;
};
const props = withDefaults(defineProps<PropType>(), {
  type: 'default',
  size: 'large',
  triggerWidth: '52px',
  popoverClass: '',
});

const Theme = {
  components: {
    DropdownButton: {},
  },
} as any;

// 计算下拉词容器的宽度
const w = ref<number | undefined>(160);
const getRect = () => {
  if (!props.popoverClass) {
    w.value = undefined;
    return;
  }
  const dom = document.querySelector(`.${props.popoverClass}`);
  if (dom) {
    const rect = dom.getBoundingClientRect();
    w.value = rect.width || undefined;
  }
};
onMounted(() => {
  setTimeout(() => {
    getRect();
  });
});
</script>

<style scoped lang="less">
.df-dropdown-button-wrapper {
  :deep(.df-dropdown-button) {
    border-radius: 8px;
    .ant-btn {
      height: 40px;
    }
    .ant-btn-lg {
      height: 48px;
    }
    .ant-btn:first-child {
      flex: 1;
      border-right-color: #fff;
    }
    .ant-btn:nth-of-type(2) {
      width: v-bind(triggerWidth);
      border-left-color: #fff;
    }

    .ant-btn-highlight {
      color: #fff;
      background-color: #ff0035;
      &:hover {
        background-color: #ff4159;
      }
      &:active {
        background-color: #ef0041;
      }
      &:disabled {
        background-color: #ff0035;
        opacity: 0.4;
      }
    }
    .ant-btn-highlight.ant-dropdown-trigger {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
