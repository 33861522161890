<!-- 用于替代 SubItem.vue -->
<template>
  <div class="comment-sub-item">
    <template v-for="(comment, idx) in commentList" :key="comment.id">
      <div
        v-if="comment.parentId === 0 || idx === 0 || isShowMore"
        class="comment-item border-0 border-b border-solid border-[rgba(0,0,0,0.1)] px-0 py-8"
      >
        <div
          :class="{
            'view-more-btn-parent': !isShowMore,
            'comment-item-father-wrapper': comment.parentId === 0,
            'comment-item-son-wrapper': comment.parentId !== 0,
          }"
        >
          <div class="flex gap-4 mobile:gap-2">
            <!-- 用户头像 -->
            <div class="inline-flex flex-shrink-0">
              <img
                v-lazy="getOssProcessedImage(comment.createdByHeadpic, `m_fill,w_100,h_100`, 'webp')"
                class="h-10 w-10 cursor-pointer rounded-full object-cover mobile:h-6 mobile:w-6"
                alt=""
                :title="comment.createdByName"
                @click="handleUserClick(comment.createdBy)"
              />
            </div>
            <div class="flex-1">
              <!-- 用户名、时间 -->
              <a-row class="comment-item-wrap1">
                <CommentUser :answer="comment" :object-detail="objectDetail" />
              </a-row>

              <!-- 评论内容、图片 -->
              <div class="comment-item-wrap2 use-word-break mb-4 mt-2">
                <div class="text-level-4 text-text-secondary">
                  <span v-if="comment.relatedId" class="related-user-name mr-2 font-semibold">@{{ comment?.relatedUserName }}</span>
                  <span>{{ comment.content }}</span>
                </div>
                <div v-if="comment?.images?.length" class="img-list mt-4 flex flex-wrap" :style="{ 'gap': 'var(--gap)' }">
                  <ImgItem
                    v-for="(url, index) in comment.images"
                    :key="index"
                    class="w-[calc(100%/var(--col)-var(--gap)*(var(--col)-1)/var(--col))] not-pc:h-auto"
                    :preview="true"
                    :url="url"
                  />
                </div>
              </div>

              <!-- 点赞、踩、删除、回复 -->
              <div
                class="comment-item-wrap3 flex"
                :class="[{ 'comment-item-wrap3-nopendding': comment.objectType === 'making' && comment.parentId === 0 }]"
              >
                <div class="btn-contain flex gap-1">
                  <div
                    v-for="item in handleGetUpDownIconList(comment)"
                    :key="item.iconType"
                    class="flex cursor-pointer items-center rounded-md px-2.5 py-1 text-text-secondary hover:bg-[rgba(73,79,135,0.05)] active:bg-[rgba(83,91,136,0.10)]"
                    :class="[{ '!text-red-default hover:bg-red-liked active:bg-red-liked': item.done }]"
                    :title="item.type === 'like' ? 'Like' : 'Unlike'"
                    @click="onToggleAction(item, comment)"
                  >
                    <CommonLikeButton v-if="item.type === 'like'" :is-liked="item.done" icon-type="nuxt-icon" :size="20" />
                    <icon-font v-else :type="item.done ? item.doneIconType : item.iconType" class="icon rotate-180 text-[20px]" />
                    <span
                      v-if="item.type === 'like' && item.count > 0"
                      class="count ml-1 text-level-4 font-semibold"
                      :class="[{ 'text-text-primary': item.done }]"
                    >
                      {{ item.count }}
                    </span>
                  </div>
                </div>
                <div class="operation ml-4 flex items-center justify-center gap-4 pl-4">
                  <span
                    v-if="comment.isCreator || useUserInfoStore.isAdmin"
                    class="delete-icon cursor-pointer text-level-4 text-text-tertiary hover:text-red-default"
                    @click="handleDeleteComment(comment.id)"
                  >
                    Delete
                  </span>
                  <span
                    class="reply-icon cursor-pointer text-level-4 text-text-tertiary hover:text-text-primary"
                    :size="4"
                    @click="handleSubCommentClick(comment)"
                  >
                    Reply
                  </span>
                </div>
              </div>

              <!-- 回复输入框 -->
              <div v-if="comment.commentVisible" class="comment-item-wrap4 mt-4">
                <CommentTextarea
                  placeholder="Reply this comment"
                  :is-reset="isReset"
                  :filter-image-object="filterImageObject"
                  :is-sub-comment="true"
                  :object-type="OBJECT_TYPE.COMMENT"
                  :comment-object="comment"
                  @change="handleCommentChange"
                  @change-filter="(val) => emit('changeFilter', val)"
                  @update-comment-list="handleUpdateCommentList"
                />
              </div>
            </div>
          </div>

          <!-- 二级评论 -->
          <div v-if="comment?.subComments?.length" class="comment-item-wrap5 pl-16 pt-8 mobile:pl-8">
            <CommentItem
              ref="commentSubItemRefs"
              :is-reset="isReset"
              :filter-image-object="filterImageObject"
              :answer="answer"
              :object-detail="objectDetail"
              :comment-list="comment.subComments"
              @change-filter="(val) => emit('changeFilter', val)"
              @update-comment-list="emit('updateCommentList')"
            />
          </div>

          <!-- 展开更多回复 -->
          <div
            v-if="comment.parentId !== 0 && commentList.length > 3 && !isShowMore"
            :class="'view-more-btn mt-8 inline-flex cursor-pointer items-center gap-2 rounded-full px-4 py-2 font-semibold text-text-secondary hover:bg-uni-5 hover:text-text-primary'"
            @click="changeShowMoreFn"
          >
            <icon-font type="icon-arrow-down" class="text-[16px]" />
            <span class="view-btn-contain text-level-4">View more replies</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { deleteCommentApi } from '@/api';
import { useUserInfo } from '@/stores/user';
import type { CommentType, ItemType } from '@/types/common';
import { OBJECT_TYPE } from '@/types/constant';
import ImgItem from './ImgItem.vue';

type PropsType = {
  objectDetail: ItemType;
  commentList: CommentType[];
  answer: any;
  isReset?: boolean;
  filterImageObject: any;
};
const props = withDefaults(defineProps<PropsType>(), {
  isReset: false,
  filterImageObject: () => ({}),
});
type eventEmit = {
  (event: 'updateCommentList'): void;
  (e: 'changeFilter', val: boolean): void;
};
const { answer } = toRefs(props);
const emit = defineEmits<eventEmit>();

const useUserInfoStore = useUserInfo();
const router = useRouter();
const { $client } = useNuxtApp();

const isShowMore = ref(props.commentList.length <= 3);
const commentSubItemRefs = ref<any>(null);

const hasCookie = computed(() => {
  return !!useMiddleCookie('utoken')?.value;
});

function handleUserClick(createdBy: number) {
  if (createdBy === useUserInfoStore.myInfo.id) {
    router.push({
      path: '/user-center',
      query: {
        active: 'projects',
      },
    });
  } else {
    router.push(`/profile/${createdBy}`);
  }
}

const changeShowMoreFn = () => {
  isShowMore.value = true;
};

const handleGetUpDownIconList = (comment: any) => {
  return [
    {
      type: 'like',
      iconType: 'icon-thumb-up',
      doneIconType: 'icon-thumb-up',
      count: comment.actionSum.like,
      done: comment.userAction.like > 0,
    },
    {
      type: 'unlike',
      iconType: 'icon-thumb-up',
      doneIconType: 'icon-thumb-up',
      count: comment.actionSum.unlike,
      done: comment.userAction.unlike > 0,
    },
  ];
};
// 处理更新评论列表
const handleUpdateCommentList = (list: any) => {
  answer.value.commentList = list;
  answer.value.commentList.forEach((comment: any) => {
    comment.commentVisible = false;
    comment.userComment = '';
  });

  // 二级回复: 添加评论后，展开所有评论
  changeShowMoreFn();
  // 一级回复: 添加评论后，展开所有评论
  if (commentSubItemRefs.value) {
    const changeShowMoreFn = commentSubItemRefs.value[0]?.changeShowMoreFn;
    changeShowMoreFn && changeShowMoreFn();
  }
};
// 处理删除评论
const handleDeleteComment = (commentId: number) => {
  handleSecondConfirm({
    apiFun: deleteCommentApi,
    type: 'deleteComment',
    apiParams: commentId,
    okText: 'Delete',
    cb: () => {
      emit('updateCommentList');
    },
  });
};
// 处理评论改变
const handleCommentChange = (value: string, answer: any) => {
  answer.userComment = value;
};
// 处理点击回复
const handleSubCommentClick = (comment: any) => {
  if ($client.token) {
    comment.commentVisible = !comment.commentVisible;
  } else {
    $client.login();
  }
};

const onToggleAction = (item: any, comment: any) => {
  if (!hasCookie.value) {
    $client.login();
    return;
  }
  handleToggleAction({ objectType: OBJECT_TYPE.COMMENT, actionType: item.type, item: comment, hasCookie: hasCookie.value });
};

defineExpose({
  changeShowMoreFn,
});
</script>

<style lang="less" scoped>
.img-list {
  --gap: 16px;
  --col: 5;
  @media screen and (max-width: 992px) {
    --col: 4;
  }
}

.operation {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 1px;
    height: 24px;
    background: rgba(0, 0, 0, 0.1);
  }
}

.comment-item:last-of-type {
  border-bottom: none;
}

.comment-sub-item {
  .comment-item {
    // 二级评论
    .comment-item-wrap5 {
      .comment-item {
        padding: 0;
        padding-bottom: 32px;
        border-bottom: none;
      }
      .comment-item:last-of-type {
        padding-bottom: 0;
      }
    }
  }
}
</style>
