<template>
  <div ref="collapsedBtnRef" class="design-files mt-4 rounded-lg border border-solid border-[#000]/10 p-4">
    <div class="text-level-4 font-semibold text-text-primary">Design Files ({{ newAttachFiles.length }})</div>
    <a-config-provider>
      <!-- 空状态 -->
      <template #renderEmpty>
        <div class="flex items-center justify-center gap-2.5 py-2.5">
          <div class="flex flex-col items-center">
            <img class="w-[140px] mobile:w-[80px]" :src="emptyImg" alt="" />
            <span class="text-[14px] text-[#999] mobile:text-[12px]">No data</span>
          </div>
        </div>
      </template>

      <!-- 文件列表 -->
      <a-list class="file-list mt-4">
        <a-list-item v-for="file in displayFiles" :key="file.uid" class="file-item group gap-4 rounded bg-background-2 py-2 !pl-2 !pr-4">
          <div class="flex max-w-full flex-1 items-center gap-3 overflow-hidden">
            <div class="file-cover flex h-[72px] w-[96px] flex-shrink-0 items-center justify-center rounded-sm bg-[#fff]">
              <img
                class="file-img h-8 w-8 object-contain"
                :class="[file.xcsFileNoCover && 'h-8 w-full', file.isXcsFile && 'w-full']"
                :src="file.fileCover"
                alt=""
              />
            </div>
            <div class="file-info flex-1 overflow-hidden">
              <div :title="file.name" class="file-name flex select-all text-level-4 font-medium text-text-primary">
                <span
                  v-for="(item, index) in getFileNameObject(file?.name)"
                  :key="index"
                  class="whitespace-nowrap"
                  :class="{ 'file-first-name': index === 'first' }"
                >
                  {{ `${item}` }}
                </span>
              </div>
              <span v-if="file.xcsTags" class="mt-2 inline-block rounded bg-uni-6/70 px-1.5 py-0.5 text-level-7 text-text-tertiary">
                {{ file.xcsTags }}
              </span>
            </div>
          </div>
          <!-- 文件右侧的icon按钮组 -->
          <div v-if="moreThanOneFile && !isPreview" class="flex gap-2 opacity-0 transition-all group-hover:opacity-100 not-pc:opacity-100">
            <!-- 下载loading -->
            <df-spin v-if="isDownloadLoading && file.uid === currentFileUid" />
            <!-- 下载 -->
            <icon-font
              v-if="!isDownloadLoading && !isDownLoadAllLoading"
              class="cursor-pointer text-[20px]"
              type="icon-a-30-Download"
              title="download this file"
              @click="downloadOne(file, { position: 'in_file' })"
            />
            <!-- Open in studio -->
            <icon-font
              v-if="isOpenInStudioBtnShow && file.isStudioFile"
              class="cursor-pointer text-[20px] mobile:hidden"
              type="icon-XCS"
              title="open in studio"
              @click="openOneInStudio(file, { position: 'up' })"
            />
            <!-- Open in XCS -->
            <icon-font
              v-if="isOpenIconShow && file.isXcsFile"
              class="cursor-pointer text-[20px] mobile:hidden"
              type="icon-XCS"
              title="open in xcs"
              @click="openOne(file)"
            />
            <!-- Import to Canvas -->
            <icon-font
              v-if="(file.isImgFile || file.isDxfFile) && isInPcXcs"
              class="cursor-pointer text-[20px] mobile:hidden"
              type="icon-Export-line"
              title="Import to Canvas"
              @click="importOneToCanvas(file)"
            />
          </div>
        </a-list-item>
        <!-- 折叠按钮 -->
        <div
          v-if="newAttachFiles.length > 2"
          class="collapse-btn mt-2 flex cursor-pointer justify-center gap-1 rounded bg-background-2 px-4 py-1.5"
          @click="onClickCollapse"
        >
          <span class="text-level-7 font-medium text-text-secondary">{{ isCollapsed ? 'View All' : 'Collapse' }}</span>
          <icon-font class="text-[12px]" type="icon-arrow-down" :style="{ transform: `rotate(${isCollapsed ? 0 : -180}deg)` }" />
        </div>
      </a-list>
    </a-config-provider>

    <!-- 按钮组 -->
    <div v-if="hasButtonGroup" id="design-files-button-wrap" class="button-wrap mt-4 flex flex-wrap justify-center gap-3">
      <!-- open in studio + Download 组合按钮 -->
      <div v-if="isOpenInStudioBtnShow && newAttachFiles.length > 0" class="flex-[1_0_100%] mobile:hidden">
        <df-dropdown-button
          type="highlight"
          size="large"
          class="design-files-dropdown-header"
          popover-class="design-files-dropdown-header"
          :disabled="isPreview || isDownLoadAllLoading || isDownloadLoading"
          @click="onOpenInStudio"
        >
          <div v-if="isDownloadLoading || isDownLoadAllLoading" class="flex items-center justify-center gap-2.5">
            <df-spin :stroke-color="'#fff'" :width="'20px'" />
            <span class="text-level-3 font-semibold">Downloading</span>
            <span class="text-level-3 font-semibold">{{ p }}%</span>
          </div>
          <div v-else class="flex items-center justify-center gap-2.5">
            <icon-font class="h-6 text-[24px]" type="icon-XCS" />
            <span class="text-level-2 font-semibold">Open in Studio</span>
          </div>
          <template #icon>
            <NuxtIcon name="df:arrow-down-m" class="text-[20px]" />
          </template>
          <template #overlay>
            <!-- w-[480px] trigger="click" -->
            <div class="flex flex-col rounded-md border border-solid border-[#F0F0F5] bg-[#fff] p-1">
              <div
                class="flex cursor-pointer items-center gap-2 rounded bg-[#fff] px-2.5 py-2 hover:bg-[#f0f0f5]"
                @click="onDownloadClick({ position: 'up' })"
              >
                <NuxtIcon name="df:icon-a-30-Download" class="text-[20px]" />
                <span class="text-text-primary">{{ downloadBtnTitle }}</span>
              </div>
            </div>
          </template>
        </df-dropdown-button>
      </div>

      <!-- Open in XCS -->
      <div v-if="isOpenBtnShow" class="flex-[1_0_0%] mobile:hidden">
        <df-btn
          class="flex w-full items-center justify-center !px-3"
          type="highlight"
          shape="square"
          size="large"
          :loading="isCallingXcs"
          :disabled="isPreview"
          @click="onOpenClick('up')"
        >
          <icon-font v-if="!isCallingXcs" class="h-6 text-[24px]" type="icon-XCS" />
          <span class="text-level-2 font-semibold">{{ xcsFileList.length > 1 ? 'Open All in XCS' : 'Open in XCS' }}</span>
        </df-btn>
      </div>

      <!-- 移动端要去掉open in studio,保留按钮形式的Download,没有studio文件时，下载按钮常驻-->
      <div
        class="hidden flex-[1_0_100%] mobile:block"
        :class="{
          '!block': !isOpenInStudioBtnShow,
          '!flex-[1_0_0%]': !isOpenInStudioBtnShow && isOpenBtnShow,
        }"
      >
        <df-btn
          :title="downloadBtnTitle"
          class="w-full items-center justify-center !px-3"
          :type="isOpenBtnShow ? 'primary' : 'highlight'"
          shape="square"
          size="large"
          :disabled="isPreview || isDownLoadAllLoading || isDownloadLoading"
          @click="onDownloadClick({ position: 'up' })"
        >
          <div v-if="isDownloadLoading || isDownLoadAllLoading" class="flex items-center gap-1">
            <df-spin :stroke-color="'#fff'" :width="'20px'" />
            <span class="text-level-2 font-semibold">Downloading</span>
            <span>{{ p }}%</span>
          </div>
          <template v-else>
            <NuxtIcon name="df:icon-a-30-Download" class="text-[20px]" />
            <span class="ml-2 whitespace-nowrap text-level-2 font-semibold">{{ downloadBtnTitle }}</span>
          </template>
        </df-btn>
      </div>

      <!-- Import to Canvas -->
      <div v-if="isImportToCanvasBtnShow" class="flex-[1_0_0%] mobile:hidden">
        <ClientOnly>
          <df-btn
            class="flex w-full items-center justify-center !px-3"
            type="default"
            shape="square"
            size="large"
            :loading="importToCanvasLoading"
            :disabled="isPreview"
            @click="onImportToCanvas"
          >
            <icon-font v-if="!importToCanvasLoading" class="h-6 text-[24px]" type="icon-Export-line" />
            <span class="text-level-2 font-semibold">Import to Canvas</span>
          </df-btn>
        </ClientOnly>
      </div>
    </div>

    <!-- 文件弹窗 -->
    <ClientOnly>
      <FilesModal ref="filesModalRef" :opts="{ position: 'up' }" :files="studioFileList" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import emptyImg from '@/assets/img/df-ip/no_data.png';
import FilesModal from './FilesModal.vue';
import { getFileNameObject, useFile } from './hooks/designFiles';

type PropsType = {
  isPreview?: boolean;
};
withDefaults(defineProps<PropsType>(), {
  isPreview: false,
});

const {
  isCollapsed,
  collapsedBtnRef,
  onClickCollapse,
  newAttachFiles,
  displayFiles,
  moreThanOneFile,
  downloadBtnTitle,
  isDownloadLoading,
  isDownLoadAllLoading,
  currentFileUid,
  isOpenIconShow,
  openOne,
  isInPcXcs,
  importOneToCanvas,
  isOpenBtnShow,
  hasButtonGroup,
  isOpenInStudioBtnShow,
  studioFileList,
  filesModalRef,
  onOpenInStudio,
  openOneInStudio,
  onDownloadClick,
  downloadOne,
  p,
  isCallingXcs,
  onOpenClick,
  xcsFileList,
  isImportToCanvasBtnShow,
  importToCanvasLoading,
  onImportToCanvas,
} = useFile();
</script>

<style lang="less" scoped>
.file-item {
  border: none;
  .file-name {
    word-break: break-all;
  }

  .file-first-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.file-item + .file-item {
  margin-top: 8px;
}

:deep(.ant-list-empty-text) {
  background: #f5f7fb;
  border-radius: 8px;
}

.button-wrap {
  @media screen and (max-width: 1260px) {
    flex-direction: column;
  }
}
</style>
