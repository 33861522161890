<template>
  <div ref="boxRef" class="rounded-lg border border-solid border-[rgba(26,26,26,0.1)] bg-[rgba(245,247,251,0.5)] px-6 py-4">
    <div class="mb-4 flex items-center justify-between mobile:flex-col mobile:items-start mobile:gap-2">
      <div class="flex items-center gap-2">
        <img :src="XtoolSvgIcon" />
        <span class="text-level-4 text-text-primary">Compatible xTool Machine</span>
      </div>

      <div class="flex cursor-pointer items-center gap-1 text-text-tertiary transition hover:text-black-hover" @click="handleClickCompatibleGuide">
        <span class="text-level-8">Guidelines for using compatible machines</span>
        <icon-font type="icon-question-line1" class="text-[16px]" />
      </div>
    </div>

    <div ref="listRef" class="relative flex items-center gap-2 overflow-x-hidden whitespace-nowrap" :class="{ 'flex-wrap': isShowAll }">
      <MachineMaterialBlock v-for="(item, index) in showList" :key="`${item.name}-${index}`" class="bg-transparent" :item="item" type="machine" />

      <div v-if="!isShowAll && isShowMore" class="more absolute right-0 top-0 flex h-full w-24 items-center justify-end" @click="handleShowAll">
        <icon-font class="icon h-6 w-6 cursor-pointer select-none justify-center rounded-full bg-[#fff] text-[16px]" type="icon-a-icon-right-m1" />
      </div>
    </div>

    <a-modal
      v-model:open="isShowModal"
      class="machine-compatible-modal"
      centered
      destroy-on-close
      :width="isPc ? 480 : '90%'"
      :footer="null"
      :closable="false"
    >
      <div class="flex flex-col items-center justify-center gap-4">
        <video class="w-full rounded-lg" :src="CompatibleMachineVideo" autoplay loop playsinline muted :controls="false"></video>

        <div class="text-xl font-bold text-text-primary">Guidelines for using compatible machines</div>

        <div class="text-level-4 text-text-secondary">
          With the compatible machines displayed, you can easily process XCS files created by other machines by clicking 'Switch Devices' in xTool
          Creative Space/Atomm Studio.
        </div>

        <df-btn type="primary" class="w-20" @click="isShowModal = false">Get</df-btn>
      </div>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import CompatibleMachineVideo from '@/assets/img/common/compatible-guide.mp4';
import XtoolSvgIcon from '@/assets/img/icon-svg/xtool.svg';
import MachineMaterialBlock from '@/components/project-details/MachineMaterialBlock.vue';
import { useDebounceFn, useResizeObserver } from '@vueuse/core';

type PropsType = {
  list: any[];
};

const props = defineProps<PropsType>();

const { sensorDetailCompatibleGuideClick } = useSensors();
const { isPc } = useScreen();

const isShowModal = ref(false);

const handleClickCompatibleGuide = () => {
  sensorDetailCompatibleGuideClick();
  isShowModal.value = true;
};

const showList = computed(() => {
  const list = props.list?.length > 0 ? props.list : [];

  return list.map((item: any) => {
    const powers = item.powerNames ?? [];
    const extra = item.extra ?? {};
    const extraName = powers.join(', ');

    return {
      name: item.name,
      extraName: extraName,
      icon: extra.icon,
      adLink: extra.adLink,
      size: 32,
      previewSize: 160,
      hover: Boolean(extra.icon),
    };
  });
});

const isShowAll = ref(false);

const isShowMore = ref(false);

const handleShowAll = () => {
  isShowAll.value = true;
};

const boxRef = ref();
const listRef = ref();

const initIsShowMore = () => {
  const s = listRef.value.scrollWidth;
  const w = listRef.value.clientWidth;
  const d = s - w;

  if (w > s || d < 10) {
    isShowMore.value = false;
    return;
  }

  isShowMore.value = true;
};

onMounted(() => {
  useResizeObserver(
    boxRef,
    useDebounceFn(async (entries: any) => {
      const entry = entries[0];
      const { width } = entry.contentRect;

      if (width <= 0) {
        return;
      }

      initIsShowMore();
    }, 300),
  );
});
</script>

<style lang="less" scoped>
.more {
  background: linear-gradient(90deg, rgba(250, 251, 253, 0) 0%, #fafbfd 66.15%);
  padding-right: 8px;

  .icon {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
    transition: filter 0.2s;
    transform: rotate(90deg);

    &:hover {
      filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08));
    }
  }
}
</style>

19154925791
