<template>
  <div class="mb-8 border-0 border-t border-solid border-[#d6d6d6] pt-8 text-lg text-[#222]">
    <client-only>
      <header class="mb-2 text-level-2 font-semibold not-pc:text-lg">Bill of Materials</header>
      <div class="rounded-lg">
        <div class="mb-2 flex items-center gap-1 text-level-4 text-text-primary">xTool parts</div>
        <div v-if="detail?.bom" id="bom-list" :class="['grid gap-4 not-pc:grid-cols-1', bom?.xtoolParts.length > 1 ? 'grid-cols-2' : 'grid-cols-1']">
          <div
            v-for="item in bom?.xtoolParts"
            :key="item.shId"
            class="mb-[-8px] flex items-center justify-between gap-2 rounded-sm bg-[#f8f8f8] p-1 pr-4"
            :class="[isPreview ? 'cursor-default' : 'cursor-pointer']"
            @click="clickItem(item)"
          >
            <div class="flex items-center rounded-sm">
              <img :src="item.image" alt="" class="mr-2 h-[100px] w-[100px] rounded-sm" />
              <div class="title line-clamp-2 flex-1 break-all text-base text-[#1A1A1A]" :title="`${item.productTitle}/${item.variantTitle}`">
                {{ item.productTitle }}/{{ item.variantTitle }}
              </div>
            </div>
            <div class="whitespace-normal text-sm text-[#575757]">x{{ item.quantity }}</div>
          </div>
        </div>
        <div v-if="detail?.bom?.otherParts" class="mb-2 mt-6 flex items-center gap-1 text-base font-semibold text-[#1A1A1A]">
          <!-- <icon-font type="icon-a-listotherparts" class="text-2xl" /> -->
          List other parts
        </div>
        <p class="mb-4 break-all text-base text-[#939393] not-pc:text-sm">
          {{ detail?.bom?.otherParts }}
        </p>
        <df-btn type="primary" size="small" class="flex items-center justify-center" :disabled="isPreview" @click="goToXTool">
          <icon-font type="icon-shop" class="text-[16px]" />
          Shop Materials
        </df-btn>
        <div class="mt-4 text-level-7 text-text-tertiary not-pc:text-sm">* Currently only available in US store</div>
      </div>
    </client-only>
  </div>
</template>
<script setup lang="ts">
import { getBomTrackId } from '@/api/project';
import { useUserInfo } from '@/stores/user';
type Props = {
  isPreview?: boolean;
  bom: any;
  detail: any;
};

const props = defineProps<Props>();
const { isPreview, bom, detail } = toRefs(props);
const { sensorBomExposure, sensorBomSopClick } = useSensors();
const userStore = useUserInfo();
const myInfo = computed(() => userStore.myInfo);
const goToXTool = async () => {
  sensorLink('Shop Materials');
  try {
    await getBomTrackIdFn();
  } catch (e) {
    console.log('getBomTrackIdFn', e);
  }
  // 产品id_变体id_数量
  const variants = bom.value.xtoolParts.map((item: any) => `${item.productId}_${item.variantId}_${item.quantity}`).join(',');
  const params = {
    email: myInfo.value.email,
    variants,
    source: 'bom',
    track_id: trackId.value,
  } as any;
  const str = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  const url = import.meta.env.VITE_XTOOL_URL;
  window.open(`${url}/cart?${str}`, '_blank');
};
const clickItem = async (item: any) => {
  if (isPreview.value) {
    return;
  }
  try {
    await getBomTrackIdFn();
  } catch (e) {
    console.log('getBomTrackIdFn', e);
  }
  sensorLink(item.productTitle + '/' + item.variantTitle);
  window.open(item.url + '&track_id=' + trackId.value, '_blank');
};
const sensorReport = () => {
  const list = bom.value.xtoolParts.map((item: any) => `${item.productTitle}/${item.variantTitle}`).join(',');
  sensorBomExposure({
    content_id: detail.value.id, // 内容id
    content_name: detail.value.title, // 内容名称
    content_tag: detail.value.tags, // 内容标签
    author_name: detail.value.createdByName, // 作者名称
    author_id: detail.value.createdBy, // 作者id
    content_type: detail.value.forSale ? 'paid' : 'free', // 内容类型
    service_type: detail.value.type, // 所属板块
    element_name: list, // 商品 名称/变体名称
  });
};
const sensorLink = (name: string) => {
  sensorBomSopClick({
    content_id: detail.value.id, // 内容id
    content_name: detail.value.title, // 内容名称
    content_tag: detail.value.tags, // 内容标签
    author_name: detail.value.createdByName, // 作者名称
    author_id: detail.value.createdBy, // 作者id
    content_type: detail.value.forSale ? 'paid' : 'free', // 内容类型
    service_type: detail.value.type, // 所属板块
    element_name: name, // 商品 名称/变体名称
  });
};
const isReportFlag = ref(false);
const observer = useIntersectionObserver(([entry]) => {
  if (entry && entry.isIntersecting && !isReportFlag.value) {
    isReportFlag.value = true;
    sensorReport();
  }
});
const trackId = ref('');
const getBomTrackIdFn = async () => {
  const res = await getBomTrackId(detail.value.id);
  trackId.value = res.data.value.data.trackId;
};

onMounted(() => {
  setTimeout(() => {
    const bomList = document.querySelector('#bom-list') as HTMLElement;
    observer.observe(bomList);
  }, 0);
});
onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>
<style lang="less" scoped>
a:hover {
  .title {
    color: theme('colors.status.link');
  }
}
</style>
