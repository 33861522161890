<template>
  <div :class="'mt-4 border-0 border-t border-solid border-[#ededed] pt-4'">
    <!-- 金额展示 -->
    <div v-if="!isShowChangePrice" class="money-display">
      <span :class="'inline-flex h-8 text-[24px] font-bold leading-8 text-text-primary pad:text-[24px] mobile:text-[24px]'">
        {{ isDiscount ? priceInfo.disCountPrice : priceInfo.origPrice }}
      </span>
      <template v-if="isDiscount">
        <span :class="'ml-2 text-level-4 text-text-tertiary line-through'">
          {{ priceInfo.origPrice }}
        </span>
        <div :class="'ml-2 inline-flex rounded bg-[#FF7C23]/[.1] px-2 text-level-4 text-[#FF7C23]'">You are saving {{ priceInfo.savePrice }}</div>
      </template>
    </div>

    <!-- logo 和 卖点 -->
    <div :class="['xtool-pick mt-4 rounded-lg bg-background-2 p-4']">
      <!-- <h2 class="flex items-center">
        <div class="inline-flex items-center gap-2 rounded-full bg-[#fff] py-[1px] pl-0.5 pr-3">
          <div v-if="isXthings" class="inline-flex h-[26px] rounded-full bg-uni-1 p-2">
            <img :src="xThingsLogo" :alt="'xThingsLogo'" :class="'w-full object-cover'" />
          </div>
          <div v-if="!isXthings" :class="'pay-icon-wrapper inline-flex h-6 w-6 items-center justify-center rounded-xl text-[16px] text-[#fff]'">
            <icon-font type="icon-vip-crown-2-fill" />
          </div>
          <span class="text-level-4 font-semibold text-[#212121]">{{ sellingTitile }}</span>
        </div>
      </h2> -->
      <div class="relative overflow-hidden">
        <ul :class="'mb-0 flex list-disc flex-col gap-2 ps-[24px]'">
          <!-- <template v-if="isXthings"> -->
          <li v-for="(point, index) in sellingPointList" :key="index" class="text-level-7 text-text-tertiary">
            <span class="use-word-break" v-html="point"></span>
          </li>
          <!-- </template> -->
          <!-- <template v-else>
            <li v-for="(point, index) in sellingPointList" :key="index" class="text-level-7 text-text-tertiary">
              <div>{{ point.title }}</div>
              <span class="use-word-break mt-0.5" v-html="point.text"></span>
            </li>
          </template> -->
        </ul>
      </div>
      <!-- 卖点提示 -->
      <div v-if="!isXthings" class="mt-2.5 border-0 border-t-[1px] border-solid border-[rgba(83,91,136,0.10)] pt-2.5">
        <ul class="mb-0 flex list-none flex-col gap-2 ps-0">
          <li v-for="(tip, index) in SellingPoint.pointTips" :key="index" :class="'flex items-center gap-2'">
            <icon-font :class="'text-[16px] text-text-tertiary pad:text-[14px] mobile:text-[14px]'" :type="tip.icon" />
            <span :class="'text-level-7 text-text-tertiary'">
              {{ tip.text }}
            </span>
          </li>
        </ul>
      </div>
    </div>

    <!-- 购买按钮 和 购买说明 -->
    <div :class="'mt-4'">
      <df-btn
        type="highlight"
        size="large"
        class="buy-button flex w-full cursor-pointer items-center justify-center px-4 font-bold pc:!h-12"
        :loading="isBuyLoading"
        :disabled="isPreview"
        @click="onBuyClick"
      >
        <icon-font class="h-6 text-[24px]" type="icon-shop-fill" />
        <span class="text-level-2 font-semibold">Buy & Use</span>
      </df-btn>
    </div>

    <!-- Credits 兑换 -->
    <div v-if="!isXthings" class="mt-4 flex items-center justify-between rounded-lg bg-[#FF7C23] bg-opacity-10 px-3 py-2">
      <div class="inline-flex items-center gap-[2px]">
        <img :src="CREDIT_ICON" alt="" class="h-6 w-6 object-cover" />
        <span class="text-level-4 text-text-primary">Redeem with</span>
        <span class="mx-1 text-level-4 font-semibold text-[#FF7C23]">{{ detail.credits }}</span>
        <span class="text-level-4 text-text-primary">Credits</span>
      </div>
      <df-btn type="special" size="mini" class="!bg-[transparent] !text-level-8" @click="onCreditBuyClick">Redeem</df-btn>
    </div>

    <div v-if="isXthings" :class="'mt-3 flex items-center gap-1'">
      <icon-font :class="'text-[16px] text-[#999] pad:text-[16px] mobile:text-[16px]'" :type="'icon-question-line'" />
      <div :class="'text-[14px] text-[#999]'">
        I brought it, why I can’t see?
        <NuxtLink :to="'https://support.xtool.com/article/1401'" class="underline hover:underline" :target="'_blank'">click here</NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CREDIT_ICON from '@/assets/img/credit/credit-icon.png';
import { initStates } from '@/components/project-details/hook';
import { useDetailsStore } from '@/stores/details';
import { useUserInfo } from '@/stores/user';
import { CONTENT_TYPE } from '@/types';
import { storeToRefs } from 'pinia';
// import xThingsLogo from '~/assets/img/xThings/xThingsLogo.svg';
import { SellingPoint } from '~/components/project-details/constant';

type PropsType = {
  isPreview?: boolean;
};
withDefaults(defineProps<PropsType>(), {
  isPreview: false,
});

// const titleMap: Record<string, string> = {
//   [CONTENT_TYPE.XTHINGS]: 'DIY Kit',
//   [CONTENT_TYPE.HOWTOS]: 'Premium Templates',
//   [CONTENT_TYPE.INSPIRATION]: 'Premium Templates',
//   [CONTENT_TYPE.PROJECT]: 'Premium Templates',
//   [CONTENT_TYPE.BUNDLE]: 'Premium Bundle',
// };

const detailsStore = useDetailsStore();
const userInfoStore = useUserInfo();
const { handleBuyClick, handleCreditBuy, isBuyLoading, payConfirmModal } = initStates();

const { detail, priceInfo } = storeToRefs(detailsStore);
const isDiscount = computed(() => detail.value.hasDiscount);
// const priceInfo = computed(() => priceComputed(detail.value));
const isXthings = computed(() => detail.value.type === CONTENT_TYPE.XTHINGS);
const isBundle = computed(() => detail.value.type === CONTENT_TYPE.BUNDLE);
// const sellingTitile = computed(() => titleMap[detail.value.type] || '');
const sellingPointList = computed<any>(() => {
  // xthings 的卖点
  if (isXthings.value) {
    return detail.value.productInfo?.sellingPoints || [];
  }
  // bundle 的卖点
  if (isBundle.value) {
    return SellingPoint.bundlePointListInfo;
  }
  // template 的卖点
  return SellingPoint.pointListInfo;
});
const isForSale = computed(() => detail.value.forSale);
const isAdmin = computed(() => userInfoStore.isAdmin);
const isCreator = computed(() => detail.value.isCreator);
const isShowChangePrice = computed(() => isForSale.value && (isAdmin.value || isCreator.value));

// const isSellingPointShowAll = ref(true);

async function getData() {
  await detailsStore.getProjectDetailById(detail.value.id);
}
function onBuyClick() {
  handleBuyClick(detail, {
    position: '顶部',
    isShowModal: true,
    isSubscription: true,
    refreshDetail: getData,
  });
}

function onCreditBuyClick() {
  handleCreditBuy('top');
}

onUnmounted(() => {
  if (payConfirmModal.value) {
    payConfirmModal.value.destroy();
  }
});
</script>

<style lang="less" scoped>
// .selling-point-btn {
//   background: linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, rgba(248, 248, 248, 0.8) 8.5%, #f8f8f8 58.59%);
// }

.pay-icon-wrapper {
  background: linear-gradient(270deg, #e6a961 10.91%, #e3a255 46.76%, #fdb96a 97.46%);
}
</style>
