<template>
  <div v-if="!isPreview && (showEdit || showDel)" class="sticky top-[230px] flex h-fit flex-col gap-8 pl-10 not-pc:hidden">
    <!-- 编辑 -->
    <a-tooltip v-if="showEdit" placement="left">
      <template #title>
        {{ !editable ? 'Your content has a version under review and cannot be edited.' : `Edit` }}
      </template>
      <ActionButton shape="circle" class="!h-[60px] !w-[60px]" :disabled="!editable" @click="handleEditClick">
        <template #icon>
          <NuxtIcon name="df:icon-edit-box" class="text-[24px]" />
        </template>
      </ActionButton>
    </a-tooltip>

    <!-- 删除 -->
    <a-tooltip v-if="showDel" placement="left">
      <template #title>Delete</template>
      <ActionButton
        class="del-btn group !h-[60px] !w-[60px]"
        shape="circle"
        :hover-syle="{ borderColor: '#ff5a00', color: 'red' }"
        @click="handleDeleteClick"
      >
        <template #icon>
          <NuxtIcon name="df:icon-Delete" class="text-[24px] group-hover:text-red-default" />
        </template>
      </ActionButton>
    </a-tooltip>

    <!-- 使用模板 -->
    <a-tooltip v-if="showCopy" placement="left">
      <template #title>Use as Template</template>
      <ActionButton shape="circle" class="!h-[60px] !w-[60px]" @click="handleUseTemplateAction">
        <template #icon>
          <NuxtIcon name="df:icon-copy" class="text-[24px]" />
        </template>
      </ActionButton>
    </a-tooltip>
  </div>
</template>

<script setup lang="ts">
import { useActions } from '@/components/project-details/common/action';
import type { AuditStatus } from '@/components/project-details/types';

type PropsType = {
  auditStatus?: AuditStatus;
};
const props = withDefaults(defineProps<PropsType>(), {
  auditStatus: undefined,
});

const {
  editable,
  showEdit,
  showDel,
  showCopy,
  // showOpenInBrowser,
  // handleOpenInBrowser,
  handleEditClick,
  handleDeleteClick,
  handleUseTemplateAction,
  isPreview,
  hasRightOptions,
} = useActions({ auditStatus: props.auditStatus });

defineExpose({
  hasRightOptions,
});
</script>

<style lang="less" scoped></style>
