<template>
  <div
    class="info-card flex border-0 border-t border-dashed border-[rgba(0,0,0,0.2)] px-6 py-3 mobile:px-4"
    :class="{ 'flex-col gap-3': itemType === 'list-machine' }"
  >
    <!-- 机器列表 -->
    <template v-if="itemType === 'list-machine'">
      <div
        class="flex"
        :class="{
          'mb-3': itemInfo.extra?.compatibleList?.lenght > 0,
        }"
      >
        <div
          :class="'left min-h-[42px] w-[250px] text-[14px] leading-[42px] text-text-primary mobile:flex mobile:w-[120px] mobile:items-center mobile:leading-5'"
        >
          {{ itemInfo.label }}
        </div>

        <div class="flex flex-wrap gap-2">
          <MachineMaterialBlock v-for="item in itemInfo.list" :key="item.key" :item="item" type="machine" />
        </div>
      </div>

      <MachineCompatible v-if="itemInfo.extra?.compatibleList?.length" :list="itemInfo.extra?.compatibleList" />
    </template>

    <template v-else>
      <div
        :class="'left min-h-[42px] w-[250px] text-[14px] leading-[42px] text-text-primary mobile:flex mobile:w-[120px] mobile:items-center mobile:leading-5'"
      >
        {{ itemInfo.label }}
      </div>
      <div class="right flex-1">
        <div v-if="itemType === 'text'" class="min-h-[42px] leading-[42px] text-text-secondary">{{ itemInfo.text }}</div>
        <div v-if="itemType === 'list-xthing-machine'" class="flex flex-wrap gap-2">
          <MachineMaterialBlock
            v-for="item in itemInfo.list"
            :key="item.key"
            :tags="item.tags"
            :popover-title="{
              text: 'Laser Power',
              align: 'left',
            }"
            :content-class-name="'bg-[#f8f8f8] px-4 py-2 rounded-lg w-[160px]'"
            :preview-image="false"
            :item="item"
            type="machine"
          />
        </div>
        <div v-if="itemType === 'list-accessories'" class="flex flex-wrap gap-2">
          <MachineMaterialBlock v-for="item in itemInfo.list" :key="item.key" :item="item" />
        </div>
        <div v-if="itemType === 'list-material'">
          <div class="flex flex-wrap gap-2">
            <MachineMaterialBlock v-for="item in itemInfo.list" :key="item.key" :item="item" type="material" />
          </div>
          <div v-if="itemInfo.extra?.text" class="use-word-break mt-3 text-level-4 text-text-secondary">
            {{ itemInfo.extra.text }}
          </div>
        </div>
        <div v-if="itemType === 'time'">
          <div class="flex min-h-[42px] items-center gap-2 leading-[42px] text-text-secondary">
            <icon-font class="text-[20px]" type="icon-time-line" />
            <span class="text-level-4">{{ itemInfo.info.text }} min</span>
          </div>
        </div>
        <div v-if="itemType === 'level'">
          <div class="flex min-h-[42px] items-center gap-2 leading-[42px]">
            <span class="text-level-4 text-text-secondary">{{ itemInfo.info.name }}</span>
            <div class="flex gap-1">
              <span
                v-for="(_, i) in 3"
                :key="i"
                class="inline-block h-1.5 w-8 rounded-full bg-background-4"
                :class="[{ 'bg-status-success': i < itemInfo.info.extra?.level }]"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import MachineCompatible from '@/components/project-details/MachineCompatible.vue';
import MachineMaterialBlock from '@/components/project-details/MachineMaterialBlock.vue';
// import { useDetailsStore } from '@/stores/details';

type PropsType = {
  itemInfo: Record<string, any>;
};
const props = withDefaults(defineProps<PropsType>(), {
  itemInfo: () => ({}),
});

// const detailsStore = useDetailsStore();

// const detail = computed(() => detailsStore.detail);
const itemType = computed(() => props.itemInfo.type);
</script>

<style lang="less" scoped>
.info-card:nth-of-type(1) {
  border-top: none;
}
</style>
