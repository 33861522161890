<template>
  <section id="xthings-overview" class="overview mb-10">
    <h3 class="mb-0 text-level-2 font-semibold text-text-primary pad:text-level-2 mobile:text-level-2">Overview</h3>

    <ul class="m-0 mt-4 pl-6 text-[16px] text-text-primary">
      <li v-for="(item, index) in overviewList" :key="index" class="overview-item" :class="[{ 'mt-2': index !== 0 }]">
        {{ item.text }}
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import { useDetailsStore } from '@/stores/details';

const detailsStore = useDetailsStore();

const detail = computed(() => detailsStore.detail);

// Overview
const overviewList = computed(() => {
  return detail.value.productInfo?.overviews?.map((item, index) => {
    return {
      key: index.toString(),
      text: item,
    };
  });
});
</script>

<style lang="less" scoped></style>
