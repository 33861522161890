<template>
  <div class="relative">
    <div class="media-box cover flex flex-col items-center gap-3">
      <!-- 标签 -->
      <SellerTag v-if="isForSale" :detail="detail" />
      <!-- 成品图/视频 -->
      <Carousel
        ref="carouselRef"
        class="group/carousel overflow-hidden rounded-lg"
        :items="mediaList"
        :show-indicator="false"
        :auto="false"
        :loop="false"
        :ssr="false"
        :gap="1"
        @change="onCarouselChange"
      >
        <template #item="{ item, index }">
          <ImgBox v-if="item.type === 'image'" :item="item" @click="onPreview" @generate-similar="handleGenerateSimilar" />
          <div v-if="VideoTypes.includes(item.type)" class="video-box flex h-full w-full items-center bg-uni-0">
            <video
              :ref="setRef"
              class="h-full w-full flex-[1_0_0%] object-contain"
              :poster="item.thumbnailUrl"
              controls
              preload="metadata"
              disablepictureinpicture
              muted
              controlslist="nodownload"
              playsinline
              @play="videoIsPlay = true"
              @pause="videoIsPlay = false"
              @ended="videoIsPlay = false"
              @contextmenu.prevent
            >
              <source :src="item.url" type="video/mp4" />
              <source :src="item.url" type="video/mov" />
              <source :src="item.url" type="video/webm" />
              <p>Your browser does not support the video tag.</p>
            </video>
            <div
              v-show="!videoIsPlay"
              class="absolute left-1/2 top-1/2 z-[2] flex h-16 w-16 -translate-x-1/2 -translate-y-1/2 cursor-pointer mobile:!hidden"
            >
              <img class="h-full w-full object-cover" :src="VideoIconPng" @click="onPlayVideo" />
            </div>
          </div>
          <div v-if="item.type === 'video'" class="absolute left-0 top-0 z-[2] h-full w-full">
            <VideoBox :ref="setRef" :item-info="item" :index="index" />
          </div>
        </template>
        <template #arrow>
          <div
            :class="[
              'arrow absolute left-[24px] top-[50%] hidden h-12 w-12 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-[#fff] pad:group-hover/carousel:hidden mobile:group-hover/carousel:hidden',
              current === 0 ? 'arrow-disabled group-hover/carousel:flex' : 'group-hover/carousel:flex',
            ]"
            @click="onClickPrev"
          >
            <icon-font type="icon-icon-right-m" class="text-[26px]" />
          </div>
          <div
            :class="[
              'arrow absolute right-[24px] top-[50%] hidden h-12 w-12 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-[#fff] pad:group-hover/carousel:hidden mobile:group-hover/carousel:hidden',
              current === mediaList.length - 1 ? 'arrow-disabled group-hover/carousel:flex' : 'group-hover/carousel:flex',
            ]"
            @click="onClickNext"
          >
            <icon-font type="icon-a-icon-right-m1" class="text-[26px]" />
          </div>
        </template>
      </Carousel>
      <a-image-preview-group :preview="{ visible, onVisibleChange, current }">
        <Lane
          v-show="mediaList.length >= 2"
          :items="mediaList"
          :gap="8"
          :column="6"
          :allow-wheel="true"
          :show-arrow="true"
          class="overflow-hidden rounded"
        >
          <template #item="{ index, item }">
            <RatioBox>
              <div
                :id="current === index ? 'current-item' : ''"
                :class="[
                  'group/item img-item relative h-full w-full cursor-pointer overflow-hidden rounded-lg border-2 border-solid p-0.5',
                  current === index ? 'border-text-primary' : 'border-[transparent]',
                ]"
                @click="onClickIndicator(index)"
              >
                <img v-lazy="item.thumbnailUrl" class="h-full w-full rounded object-cover" alt="" />
                <a-image
                  :src="item.thumbnailUrl"
                  :preview="{ src: item.type === 'image' ? item.url : item.thumbnailUrl }"
                  :preview-mask="false"
                  class="hidden !h-full !w-full !rounded object-cover"
                />
                <div
                  class="absolute inset-[2px] z-10 rounded group-hover/item:opacity-0"
                  :class="[index !== current ? `bg-[#000] opacity-30` : '']"
                ></div>
                <img
                  v-if="[...VideoTypes, 'video'].includes(item.type)"
                  class="absolute left-1/2 top-1/2 z-[2px] h-8 w-8 -translate-x-1/2 -translate-y-1/2 pad:h-6 pad:w-6 mobile:h-6 mobile:w-6"
                  :src="VideoIconPng"
                  alt=""
                />
              </div>
            </RatioBox>
          </template>
          <template #arrow="{ prev, next, allowPrev, allowNext }">
            <div
              v-if="allowPrev"
              :class="'absolute left-0 top-0 z-[5] flex h-full w-[32px] cursor-pointer items-center justify-center rounded-l-lg bg-uni-0/30 text-[24px] text-[#fff] hover:bg-uni-0/60'"
              @click="prev"
            >
              <icon-font type="icon-icon-right-m" />
            </div>
            <div
              v-if="allowNext"
              :class="'absolute right-0 top-0 z-[5] flex h-full w-[32px] cursor-pointer justify-center rounded-r-lg bg-uni-0/30 text-[24px] text-[#fff] hover:bg-uni-0/60'"
              @click="next"
            >
              <icon-font type="icon-a-icon-right-m1" />
            </div>
          </template>
        </Lane>
      </a-image-preview-group>
      <!-- 卖点icons -->
      <SellerIcons v-if="isForSale && !hasRight" :detail="detail" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Carousel from '@/components/common/carousel/index.vue';
import { type CarouselInstance } from '@/components/common/carousel/types';
import Lane from '@/components/common/lane/index.vue';
import { useAiGenerate } from '@/components/project-details/common/ai-generate';
import { useDetailsStore } from '@/stores/details';
import dayjs from 'dayjs';
import NoImagePng from '~/assets/img/project-detail/NoImage.png';
import VideoIconPng from '~/assets/img/project-detail/video_icon.png';
import ImgBox from './ImgBox.vue';
import SellerIcons from './SellerIcons.vue';
import SellerTag from './SellerTag.vue';
import VideoBox from './VideoBox.vue';

const VideoTypes = ['mp4', 'mov', 'webm'];

const detailsStore = useDetailsStore();
const { handleTryAi } = useAiGenerate();
const { sensorGenerateSimilarClick } = useSensors();

const detail = computed(() => detailsStore.detail);
const isForSale = computed(() => detail.value.forSale);
const hasRight = computed(() => detail.value.hasRight);
const updatedAt = computed(() => detail.value.updatedAt);
const aiImages = computed(() => detail.value.aiImages || []);
const coverList = computed(() => {
  const cover = detail.value.cover;
  if (!cover) {
    return [NoImagePng];
  }
  // 没有 updatedAt 说明是预览，把封面加到成品图列表中
  if (!updatedAt.value) {
    return [cover];
  }
  // 晚于2024-8-30的案例，若有封面,则把封面加到详情页(编辑器改版前默认会把第一张图片作为封面,改版后则不会)
  const isAfter20240830 = updatedAt.value > dayjs('2024-08-30').unix();
  return isAfter20240830 ? [cover] : [];
});
const videoList = computed(() => detail.value.videos || []);
const imageList = computed(() => detail.value.images || []);
// 媒体列表，顺序：封面、AI图、视频、成品图
const mediaList = computed(() => {
  const videos = videoList.value.map((video: any) => {
    const suffix = video.name?.split('.')?.pop()?.toLowerCase();
    if (VideoTypes.includes(suffix)) {
      return {
        type: suffix,
        url: video.videoUrl,
        thumbnailUrl: getOssProcessedImage(video.thumbnailUrl, '', 'webp'),
      };
    }
    return {
      type: 'video',
      url: video.videoUrl,
      thumbnailUrl: getOssProcessedImage(video.thumbnailUrl, '', 'webp'),
    };
  });
  const cover = coverList.value.map((c) => ({
    type: 'image',
    url: getOssProcessedImage(c, '', 'webp'),
    thumbnailUrl: getOssProcessedImage(c, 'm_fill,w_1200,h_900', 'webp'),
  }));
  const aiImgs = aiImages.value.map((aiImg: any) => ({
    type: 'image',
    url: getOssProcessedImage(aiImg, '', 'webp'),
    thumbnailUrl: getOssProcessedImage(aiImg, 'm_fill,w_1200,h_900', 'webp'),
    mimetype: showAiGenerateBtn.value ? 'image/ai' : 'image/webp',
  }));
  const images = imageList.value.map((img) => ({
    type: 'image',
    url: getOssProcessedImage(img, '', 'webp'),
    thumbnailUrl: getOssProcessedImage(img, 'm_fill,w_1200,h_900', 'webp'),
    mimetype: checkImg(img, '.gif') ? 'image/gif' : 'image/webp',
  }));
  return [...cover, ...aiImgs, ...videos, ...images];
});
const aiExtra = computed(() => detail.value.aiExtra ?? {});
const showAiGenerateBtn = computed(() => aiExtra.value.style || aiExtra.value.scene);

useHead({
  link: mediaList.value.map((media) => ({
    rel: 'preload',
    as: 'image',
    href: media.type === 'image' ? media.url : media.thumbnailUrl,
  })),
});

const visible = ref(false);
const current = ref(0);
const onVisibleChange = (value: boolean) => {
  visible.value = value;
};

const carouselRef = shallowRef<CarouselInstance>();
const onPreview = () => {
  visible.value = true;
};
const handleGenerateSimilar = () => {
  sensorGenerateSimilarClick({ source: 'mainpic' });
  handleTryAi(aiExtra.value);
};
const onClickIndicator = async (index: number) => {
  if (current.value !== index) {
    current.value = index;
    carouselRef.value?.move(index);
  }
};
const onClickPrev = () => {
  carouselRef.value?.prev();
};
const onClickNext = () => {
  carouselRef.value?.next();
};
const onCarouselChange = (index: number) => {
  current.value = index;
  scrollItemIntoCenter();
  pauseVideo();
};
const scrollItemIntoCenter = async () => {
  await nextTick();
  const itemDom: any = document.querySelector('#current-item');
  if (itemDom && itemDom.scrollIntoViewIfNeeded) {
    itemDom.scrollIntoViewIfNeeded(false);
  }
};
// 暂停视频播放
const videoRefs = ref<any[]>([]);
function setRef(el: any) {
  if (el) {
    videoRefs.value.push(el);
  }
}
function pauseVideo() {
  if (videoRefs.value.length > 0) {
    videoRefs.value.forEach((video: any) => {
      if (video && video.stopVideo) {
        video.stopVideo();
      }
      if (video && video.pause) {
        video.pause();
      }
    });
  }
}
const videoIsPlay = ref(false);
function onPlayVideo() {
  videoIsPlay.value = true;
  if (videoRefs.value.length > 0) {
    videoRefs.value.forEach((video: any) => {
      if (video && video.play) {
        video.play();
      }
    });
  }
}
// const isControlsShow = ref(false);
// onMounted(() => {
//   const timer = setTimeout(() => {
//     isControlsShow.value = true;
//     clearTimeout(timer);
//   }, 2000);
// });
</script>

<style lang="less" scoped>
.media-box {
  position: sticky;
  top: 64px;
}

.video-box {
  video::-webkit-media-controls-panel {
    flex: 1 0 0% !important;
  }
}

:deep(.df-carousel-wrap) {
  height: auto;
}
:deep(.df-carousel) {
  padding-top: 75%;
  .df-carousel-track {
    position: absolute;
    top: 0;
    left: 0;
  }
  .df-carousel-item {
    border-radius: 6px;
    overflow: hidden;
  }
}
:deep(.df-lane-wrap) {
  height: auto;
}
:deep(.img-item) {
  .ant-image {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
  }
}

.arrow {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));
}
.arrow-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@media screen and (max-width: 992px) {
  :deep(.df-lane-item) {
    width: 70px;
  }
}
</style>
