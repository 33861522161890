<template>
  <a-modal :open="show" width="720px" title="Please select the file to open" centered @cancel="closeModal">
    <div class="list flex flex-col gap-2">
      <div v-for="file in files" :key="`${file.uid}-${file.id}`" class="item flex items-center gap-4">
        <a-checkbox :checked="selectedIds.includes(`${file.uid}-${file.id}`)" @change="onChange($event, file)" />
        <div class="flex max-w-full flex-1 items-center gap-3 overflow-hidden rounded-lg bg-newBg-subtlest p-2">
          <div class="file-cover flex h-[72px] w-[96px] flex-shrink-0 items-center justify-center rounded bg-[#fff]">
            <img
              class="file-img h-8 w-8 object-contain"
              :class="[file.xcsFileNoCover && 'h-8 w-full', file.isXcsFile && 'w-full']"
              :src="file.fileCover"
              alt=""
            />
          </div>
          <div class="file-info flex-1 overflow-hidden">
            <div :title="file.name" class="file-name flex select-all text-level-4 font-semibold">
              <span
                v-for="(item, index) in getFileNameObject(file?.name)"
                :key="index"
                class="whitespace-nowrap"
                :class="{ 'truncate': index === 'first' }"
              >
                {{ `${item}` }}
              </span>
            </div>
            <span v-if="file.xcsTags" class="mt-2 inline-block rounded bg-uni-6/70 px-1.5 py-0.5 font-sans text-level-7 text-text-tertiary">
              {{ file.xcsTags }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="footer flex items-center justify-between">
        <label for="selectAll" class="flex cursor-pointer items-center gap-4">
          <a-checkbox id="selectAll" :indeterminate="isIndeterminate" :checked="selectedFiles.length === props.files.length" @change="onSelectAll" />
          <span class="text-level-7">Select all</span>
        </label>
        <div class="flex items-center gap-4">
          <df-btn type="default" shape="square" @click="closeModal">Cancel</df-btn>
          <df-btn type="primary" shape="square" :disabled="selectedFiles.length === 0" @click="onOpen">Open ({{ selectedFiles.length }})</df-btn>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script lang="ts" setup>
import { getFileNameObject, useFile } from './hooks/designFiles';

type PropsType = {
  files: any[];
  opts: {
    position?: 'up' | 'bottom';
  };
};
const props = defineProps<PropsType>();

const { openAllInStudio } = useFile();

const show = ref(false);
const selectedFiles = ref<any[]>([]);
const selectedIds = ref<string[]>([]);

const isIndeterminate = computed(() => {
  return selectedFiles.value.length > 0 && selectedFiles.value.length < props.files.length;
});

function open() {
  show.value = true;
}

const closeModal = () => {
  show.value = false;
  selectedFiles.value = [];
  selectedIds.value = [];
};

function onChange(e: any, file: any) {
  if (e.target.checked) {
    selectedFiles.value.push(file);
  } else {
    selectedFiles.value = selectedFiles.value.filter((item: any) => `${item.uid}-${item.id}` !== `${file.uid}-${file.id}`);
  }
  selectedIds.value = selectedFiles.value.map((item: any) => `${item.uid}-${item.id}`);
}

function onSelectAll(e: any) {
  if (e.target.checked) {
    selectedFiles.value = props.files;
  } else {
    selectedFiles.value = [];
  }
  selectedIds.value = selectedFiles.value.map((item: any) => `${item.uid}-${item.id}`);
}

function onOpen() {
  openAllInStudio(selectedFiles.value, props.opts);
}

defineExpose({
  open,
});
</script>

<style lang="less" scoped></style>
