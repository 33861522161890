<template>
  <div class="header-main mx-auto flex max-w-[1200px] px-[40px] pb-8 pt-6 pad:px-0 mobile:px-0" :class="[hasSideAction && 'max-w-[1300px]']">
    <div
      id="detail-header"
      class="relative flex w-full gap-8 pad:!w-full pad:flex-col pad:px-5 mobile:!w-full mobile:flex-col mobile:px-5"
      :class="{
        'w-[calc(100%-100px)]': hasSideAction,
      }"
    >
      <MediaList class="w-[55%] pad:w-[100%] mobile:w-[100%]" />
      <DescInfo class="w-[45%] pad:w-[100%] mobile:w-[100%]" v-bind="$attrs" />
    </div>
    <SideAction v-show="!isOpenFromStudioEditor()" ref="sideActionRef" v-bind="$attrs" />
  </div>
</template>

<script setup lang="ts">
import DescInfo from './DescInfo.vue';
import MediaList from './MediaList.vue';
import SideAction from './SideAction.vue';

const sideActionRef = ref<any>(null);
const hasSideAction = ref(false);
onMounted(() => {
  nextTick(() => {
    hasSideAction.value = sideActionRef.value?.hasRightOptions;
  });
});
</script>

<style lang="less" scoped></style>
