<template>
  <div class="comment-input">
    <div v-if="!isLogin">
      <div class="flex items-center gap-4">
        <div class="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-background-2">
          <UserOutlined class="text-[24px] text-text-secondary" />
        </div>
        <div
          :class="'login-tip-row flex h-12 w-full cursor-pointer items-center rounded-lg border border-solid border-[transparent] bg-background-2 px-6 text-level-2 text-text-tertiary hover:border-[rgba(34,34,34,0.2)]'"
          @click="handleLoginTipClick"
        >
          Login to leave your comment
        </div>
      </div>
    </div>

    <div v-else>
      <div>
        <div class="mb-4 flex items-center gap-4">
          <div class="flex-shrink-0 not-pc:hidden">
            <img v-lazy="headpic" class="h-10 w-10 rounded-full object-cover" alt="" />
          </div>
          <div class="flex-[1_0_0%]">
            <df-textarea
              id="comment-area"
              class="comment-input-area w-full"
              size="large"
              :placeholder="placeholder"
              :value="commentObject.userComment"
              :maxlength="1000"
              :auto-size="{ minRows: 1 }"
              @change="updateValue($event, commentObject)"
            />
          </div>
        </div>

        <div v-if="uploadFileList.length > 0" class="img-list mb-4 flex flex-wrap gap-4 pl-[64px] not-pc:pl-0">
          <CommentImgItem
            v-for="img in uploadFileList"
            :key="img.uid"
            class="w-[calc(100%/var(--col)-var(--gap)*(var(--col)-1)/var(--col))] not-pc:h-auto"
            :img-info="img"
            @delete="deleteFile"
          />
        </div>

        <div class="flex justify-between pl-[56px] not-pc:pl-0" :class="[{ '!justify-end': uploadFileList.length >= 8 }]">
          <div v-if="uploadFileList.length < 8" class="flex items-center gap-4">
            <a-upload
              :file-list="uploadFileList"
              :capture="undefined"
              class="upload-box"
              name="file"
              multiple
              :max-count="8"
              :support-server-render="true"
              :accept="FILE_LIMIT.types.join(', ')"
              :show-upload-list="false"
              :before-upload="onBeforeFileUpload"
              :custom-request="onCustomRequest"
              @change="onFileChange"
            >
              <df-btn type="tertiary" size="small" class="flex items-center gap-2.5">
                <icon-font class="text-[24px]" type="icon-a-sucai1" />
                <span class="!ml-0">Share images</span>
              </df-btn>
            </a-upload>
            <span class="text-level-4 text-text-tertiary">{{ uploadFileList.length }}/8</span>
          </div>

          <df-btn
            type="primary"
            size="small"
            :loading="submitLoading"
            :disabled="!commentObject.userComment?.trim() && uploadFileList.length <= 0"
            @click="handleAddCommentClick(commentObject)"
          >
            Post
          </df-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { doCommentApi, getCommentListApi } from '@/api';
import { useDetailsStore } from '@/stores/details';
import { useUserInfo } from '@/stores/user';
import { OBJECT_TYPE } from '@/types/constant';
import { UserOutlined } from '@ant-design/icons-vue';
import { useComment, useUploadFile } from './hooks';

type propType = {
  commentObject: any;
  placeholder: string;
  objectType?: OBJECT_TYPE;
  commontType?: OBJECT_TYPE;
  isSubComment?: boolean;
  isReset?: boolean; // 是否重置勾选图片
  filterImageObject: any;
};
const props = withDefaults(defineProps<propType>(), {
  objectType: OBJECT_TYPE.ANSWER,
  isSubComment: false,
  commontType: OBJECT_TYPE.ANSWER,
  isReset: false,
  filterImageObject: () => ({}),
});
type eventEmits = {
  (e: 'change', value: string, commentObject: any): void;
  (e: 'updateCommentList', list: any): void;
  (e: 'changeFilter', hasImages: boolean): void;
};
const emit = defineEmits<eventEmits>();
const { filterImageObject } = toRefs(props);

const { onBeforeFileUpload, FILE_LIMIT, uploadFileList, onCustomRequest, onFileChange, deleteFile } = useUploadFile();
const { handleLoginTipClick, handleSensorsComment } = useComment();
const userInfoStore = useUserInfo();
const detailsStore = useDetailsStore();

// 是否登录
const isLogin = computed(() => userInfoStore.myInfo.name);
// 头像
const headpic = computed(() => getOssProcessedImage(userInfoStore.myInfo?.headpic, 'm_fill,w_100,h_100', 'webp'));

const submitLoading = ref(false);
const updateValue = (event: any, commentObject: any) => {
  emit('change', event.target.value, commentObject);
};

const handleAddCommentClick = async (answer: any) => {
  // 检查内容和图片是否都为空
  if (!answer.userComment?.trim() && uploadFileList.value.length <= 0) {
    return message.info('Content or images cannot be empty');
  }

  // 检查图片是否上传完成
  const images = uploadFileList.value.map((item: any) => item.url);
  const isUploaded = images.every((img: any) => Boolean(img));
  if (!isUploaded) {
    message.error('Please wait for the image to finish uploading');
    return;
  }

  try {
    submitLoading.value = true;
    handleSensorsComment('comment', answer);
    const { data } = await doCommentApi({
      objectType: props.objectType,
      objectId: answer.id,
      content: answer.userComment,
      images,
    });
    if (data.value?.code === 0) {
      handleSensorsComment('finish', answer);
      handleGetCommentList(answer);
      answer.userComment = '';
      uploadFileList.value = [];
    }
  } catch (error) {
    console.warn(error);
  } finally {
    submitLoading.value = false;
  }
};

const handleGetCommentList = async (answer: any) => {
  // console.log('props.isSubComment :>> ', props.isSubComment);
  if (props.isReset) {
    emit('changeFilter', false); // 通知父组件重置勾选图片
  }

  const { data } = await getCommentListApi({
    objectType: props.isSubComment ? answer.objectType : props.commontType,
    objectId: props.isSubComment ? answer.objectId : answer.id,
    filterHasImage: props.isReset ? false : filterImageObject.value.filterHasImage,
    pageSize: -1,
    current: 1,
  });

  if (data.value?.code === 0) {
    detailsStore.updateCommentCount(data.value?.data?.countCommentDetails);
    if (props.isSubComment) {
      emit('updateCommentList', data.value.data.list);
      return;
    }
    answer.commentList = data.value.data.list;
    answer.commentList.forEach((comment: any) => {
      comment.commentVisible = false;
      comment.userComment = '';
    });
  }
};
</script>

<style lang="less" scoped>
.img-list {
  --gap: 16px;
  --col: 5;
  @media screen and (max-width: 992px) {
    --col: 4;
  }
}
</style>
