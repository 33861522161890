<template>
  <div class="mt-4 flex justify-between">
    <div class="flex items-center">
      <AuthorUserImg
        v-if="detail.createdBy"
        img-size="32px"
        :clickable="!isPreview"
        :user-id="detail.createdBy"
        :user-img="detail.createdByHeadpic"
        :user-name="detail.createdByName"
        :is-show-user-card="!isPreview"
      />
      <template v-if="!detail.createdByDeleted && !isCreator && !isPreview">
        <span class="mx-2 items-center text-level-2 font-semibold text-text-primary opacity-30">·</span>
        <ClientOnly>
          <FollowButton
            :id="detail.createdBy"
            source="case"
            class="follow-btn"
            :class="[{ 'followed-btn': isFollowed }]"
            :name="detail.createdByName"
            :size="'small'"
            :is-my-follow="isFollowed"
            :content-type="detail.type"
            my-follow-type="text"
            default-type="text"
            @change="handleFollowChange"
          />
        </ClientOnly>
      </template>
    </div>
    <a-space v-if="!isForSale" :size="12">
      <div v-if="downloadCnt > 0" class="flex items-center gap-0.5">
        <NuxtIcon name="df:icon-a-30-Download" class="text-[16px] text-text-tertiary" />
        <span class="text-level-4 text-text-tertiary">{{ downloadCnt }}</span>
      </div>
      <div v-if="actionSumView > 0" class="flex items-center gap-0.5">
        <NuxtIcon name="df:icon-eye-line" class="text-[18px] text-text-tertiary" />
        <span class="text-level-4 text-text-tertiary">{{ actionSumView }}</span>
      </div>
    </a-space>
  </div>
</template>

<script setup lang="ts">
import type { DetailProvide } from '@/components/project-details/types';
import { useDetailsStore } from '@/stores/details';
import { getUserInfoById } from '~/api';

const { isPreview } = inject('detail-common') as DetailProvide;

const detailsStore = useDetailsStore();
const { isLogin } = useLoginStatus();
const detail = computed(() => detailsStore.detail);
const downloadCnt = computed(() => detailsStore.downloadCnt);
const isForSale = computed(() => detail.value.forSale);
const actionSumView = computed(() => (isPreview ? 0 : detail.value.actionSum?.view));
const createdBy = computed(() => detail.value.createdBy);
const isCreator = computed(() => detail.value.isCreator);
const isFollowed = ref(false);
async function getOtherUserInfo() {
  if (isPreview || !isLogin.value || isCreator.value) {
    return;
  }

  try {
    const res = await getUserInfoById.fetch({ id: createdBy.value });
    if (res.code === 0) {
      isFollowed.value = Boolean(res?.data.isFollowed);
    } else {
      console.error(res?.message || 'Unknown system error!');
    }
  } catch (err) {
    console.error(err);
  }
}

function handleFollowChange(value: boolean) {
  isFollowed.value = value;
}

getOtherUserInfo();
</script>

<style lang="less" scoped>
:deep(.author-user-img) {
  .user-name {
    font-size: 14px;
    color: theme('colors.text.secondary');
  }
}

:deep(.follow-btn) {
  .df-btn {
    padding-left: 2px;
    background-color: transparent !important;
    color: theme('colors.red.default');
    transition: none;
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;
    .icon {
      display: none;
    }
    span.text.unfollow-text {
      font-size: 14px;
      margin-inline-start: 0;
    }
    &:hover {
      color: theme('colors.red.hover');
    }
  }
}
:deep(.followed-btn) {
  .df-btn {
    .followed-text {
      color: theme('colors.text.tertiary');
      font-size: 14px;
      &:hover {
        color: theme('colors.black.default');
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .follow-button {
    padding: 0;
    :deep(.ant-btn) {
      .anticon + span {
        margin-left: 0;
      }
    }
  }
}
</style>
