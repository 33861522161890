<template>
  <div class="file-item mb-6 overflow-hidden rounded-xl border border-solid border-[rgba(0,0,0,0.1)] bg-[#fefeff]">
    <div class="title flex justify-between px-6">
      <div class="left flex gap-2 py-6">
        <div class="h-12 w-12 flex-shrink-0">
          <img class="h-full w-full object-cover" :src="extraInfo.icon" alt="" />
        </div>
        <div class="flex flex-col justify-center gap-1.5">
          <div class="one-line-ellipsis text-level-2 font-semibold text-uni-1" :title="fileInfo.name">{{ fileInfo.name }}</div>
          <span class="text-level-7 text-text-tertiary">{{ fileSize }}</span>
        </div>
      </div>

      <div class="flex items-center gap-3">
        <div
          class="flex cursor-pointer items-center gap-2 text-level-2 text-uni-1"
          :class="[extraInfo.fileType === 'txt' ? 'flex' : 'hidden pad:flex mobile:flex']"
          @click="onDownload"
        >
          <icon-font type="icon-a-30-Download" />
          <span>Download</span>
        </div>
      </div>
    </div>

    <div v-if="isPc">
      <FilePreview ref="filePreviewRef" :type="extraInfo.fileType" :name="fileInfo.name" :src="fileInfo.url" />
    </div>
  </div>
</template>

<script setup lang="ts">
import DocxPng from '@/assets/img/file-preview/docx_icon.png';
import PdfPng from '@/assets/img/file-preview/pdf_icon.png';
import TxtPng from '@/assets/img/file-preview/txt_icon.png';
import { FilePreviewType } from '@/components/file-preview/types';

type PropsType = {
  fileInfo: any;
};
const props = defineProps<PropsType>();

const { $client } = useNuxtApp();
const { isLogin } = useLoginStatus();

const extraInfo = computed(() => {
  const fileType = props.fileInfo.name.toLowerCase().split('.').pop();
  let icon: string;
  let type: FilePreviewType;
  switch (fileType) {
    case 'txt':
      icon = TxtPng;
      type = FilePreviewType.Txt;
      break;
    case 'pdf':
      icon = PdfPng;
      type = FilePreviewType.Pdf;
      break;
    default:
      icon = DocxPng;
      type = FilePreviewType.Docx;
  }
  return {
    fileType: type,
    icon,
  };
});

const { isPc } = useScreen();

const fileSize = ref<string | number>(0);
async function getFileSize(url: string) {
  try {
    const response = await fetch(url, {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }
    const contentLength = response.headers.get('Content-Length');
    if (contentLength) {
      return parseInt(contentLength, 10);
    } else {
      throw new Error('Content-Length header is missing');
    }
  } catch (err) {
    console.error('There has been a problem with your fetch operation:', err);
  }
}
watch(
  () => {
    return props.fileInfo;
  },
  () => {
    getFileSize(props.fileInfo.url).then((size: number | undefined) => {
      if (!size) {
        return;
      }
      fileSize.value = bytesToSize(size);
    });
  },
  {
    immediate: true,
    deep: true,
  },
);

// 字节转成MB/KB(精准转换)
function bytesToSize(bytes: number) {
  if (bytes === 0) {
    return '0 B';
  }
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
}

const filePreviewRef = ref<any>(null);

// 通过地址下载文件
const onDownload = () => {
  if (!isLogin.value) {
    $client.login();
    return;
  }
  const url = props.fileInfo.url;
  const link = document.createElement('a');
  link.href = url;
  link.download = props.fileInfo.name;
  link.click();
  link.remove();
};
</script>

<style lang="less" scoped>
.file-item {
  box-shadow: 0px 4px 9.8px 0px rgba(0, 0, 0, 0.1);
}
</style>
