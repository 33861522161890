<template>
  <div class="file-preview">
    <DocxPreview v-if="type === FilePreviewType.Docx" ref="previewRef" :name="name" :file-url="src" />
    <PdfPreview v-if="type === FilePreviewType.Pdf" ref="previewRef" :name="name" :file-url="src" />
    <TxtPreview v-if="type === FilePreviewType.Txt" ref="previewRef" :name="name" :file-url="src" />
  </div>
</template>

<script setup lang="ts">
import DocxPreview from './DocxPreview.vue';
import PdfPreview from './PdfPreview.vue';
import TxtPreview from './TxtPreview.vue';
import { FilePreviewType } from './types';

type PropsType = {
  type: FilePreviewType;
  src: string;
  name?: string;
};
withDefaults(defineProps<PropsType>(), {
  type: FilePreviewType.Docx,
  src: '',
  name: '',
});

const previewRef = ref();
defineExpose({
  open: () => {
    if (previewRef.value?.open) {
      previewRef.value.open();
    }
  },
  download: () => {
    if (previewRef.value?.download) {
      previewRef.value.download();
    }
  },
});
</script>

<style lang="less" scoped></style>
