<template>
  <div class="interactive-wrapper">
    <!-- 点赞/收藏/分享 -->
    <div v-if="isPublish" class="relative mt-4 flex gap-4">
      <a-tooltip placement="top">
        <template #title>{{ isMyLike ? 'Unlike' : 'Like' }}</template>
        <div class="w-1/3">
          <ActionButton
            shape="default"
            class="!h-[64px] w-full mobile:!h-[48px]"
            :active="isMyLike"
            :disabled="isPreview"
            :text="likeCount"
            @click="onActionTrigger('like')"
          >
            <template #icon>
              <CommonLikeButton :is-liked="isMyLike" icon-type="nuxt-icon" :size="24" />
            </template>
          </ActionButton>
        </div>
      </a-tooltip>

      <a-tooltip placement="top">
        <template #title>
          {{ isMyFavorite ? 'Remove from your Bookmarks' : 'Add to Bookmarks' }}
        </template>
        <div class="w-1/3">
          <ActionButton
            shape="default"
            class="!h-[64px] w-full mobile:!h-[48px]"
            :active="isMyFavorite"
            icon-type="nuxt-icon"
            icon="icon-bookmark-line2"
            active-icon="icon-a-bookmark-fill"
            :disabled="isPreview"
            :text="favoriteCount"
            @click="onActionTrigger('favorite')"
          />
        </div>
      </a-tooltip>

      <a-tooltip placement="top" title="Share">
        <div class="w-1/3" title="Share">
          <ActionButton v-if="!isMounted" shape="default" class="!h-[64px] w-full mobile:!h-[48px]" :disabled="isPreview">
            <template #icon>
              <NuxtIcon name="df:icon-share-line" class="text-[24px]" />
            </template>
          </ActionButton>
          <CommonSharePot v-else :trigger="'click'" :title="shareTitle" :description="shareDescription" :jump="false" :cb="cb">
            <ActionButton shape="default" class="!h-[64px] w-full mobile:!h-[48px]" :disabled="isPreview">
              <template #icon>
                <NuxtIcon name="df:icon-share-line" class="text-[24px]" />
              </template>
            </ActionButton>
          </CommonSharePot>
        </div>
      </a-tooltip>
    </div>

    <!-- 更新时间/沉贴/恢复 -->
    <div class="report-wrapper mt-4 flex items-center justify-between">
      <div class="page-footer text-level-7 text-text-tertiary">
        <ClientOnly>
          <div :title="getUpdateTime(detail.updatedAt).format('YYYY-MM-DD HH:mm:ss')">
            <span>
              {{ detail.status === 'published' ? 'Edited ' : 'Last edited ' }}
            </span>
            <span>
              {{ getUpdateTime(detail.updatedAt).format('YYYY/MM/DD') }}
            </span>
          </div>
        </ClientOnly>
      </div>
      <div class="flex items-center justify-between gap-2">
        <!-- 沉贴/恢复 -->
        <a-popconfirm
          v-if="isAdmin && !isForSale"
          :open="downBtnVisiable"
          title="Are you sure？"
          @confirm="onToggleFreeMaking"
          @open-change="handleVisiableChange"
        >
          <a-button class="flex items-center p-0 hover:opacity-85" type="text" :title="isOnLine ? '沉贴' : '恢复'" :disabled="isPreview">
            <ClientOnly>
              <icon-font
                class="h-5 text-[20px]"
                :class="[isOnLine ? 'text-[#ff4d4f]' : 'text-[#00755f]']"
                :type="isOnLine ? 'icon-Frame1' : 'icon-unlist'"
              />
            </ClientOnly>
          </a-button>
        </a-popconfirm>
        <!-- 下架/上架 -->
        <template v-if="isAdmin && isForSale && !audit">
          <a-tooltip placement="bottom">
            <template #title>
              {{ isCaseOn ? 'remove' : 'unlist' }}
            </template>
            <a-button
              class="flex items-center p-0 hover:opacity-85"
              type="text"
              :title="isCaseOn ? '下架' : '上架'"
              :disabled="isPreview"
              @click="onToggleDetail"
            >
              <icon-font
                class="h-5 text-[20px]"
                :class="[isCaseOn ? 'text-[#ff4d4f]' : 'text-[#00755f]']"
                :type="isCaseOn ? 'icon-remove' : 'icon-unlist'"
              />
            </a-button>
          </a-tooltip>
        </template>
        <!-- 举报 -->
        <div
          v-if="!isCreator"
          class="flex cursor-pointer items-center gap-1 text-level-7 text-text-tertiary hover:text-text-primary"
          :disabled="isPreview"
          @click="openReportModal"
        >
          <WarningOutlined class="h-[12px] text-[12px]" />
          <span>Report</span>
        </div>
      </div>
      <ReportModal ref="reportModalRef" @send="getReportStatus" />
    </div>

    <!-- 上架/下架弹窗 -->
    <ClientOnly>
      <ReportShelfModal ref="toggleShelfRef" @sended="isCaseOnComputed = false" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { onShelvesMakingApi, recoverMakingApi } from '@/api/project-details';
import { initStates as initDetailsStates } from '@/components/project-details/hook';
import type { DetailProvide } from '@/components/project-details/types';
import { useDetailsStore } from '@/stores/details';
import { useUserInfo } from '@/stores/user';
import { PageName, SceneName } from '@/types/ga4';
import moment from 'moment';
import { getReportStatusApi } from '~/api';
import { downMakingApi } from '~/api/project-details';
import { MStatus, type ItemType } from '~/types';

type Props = {
  isPreview?: boolean;
  previewDetail?: ItemType;
  previewType?: string;
};
const props = withDefaults(defineProps<Props>(), {
  isPreview: false,
  previewDetail: undefined,
  previewType: undefined,
});
const { isPreview } = toRefs(props);
const { audit } = inject('detail-common') as DetailProvide;

const detailsStore = useDetailsStore();
const userInfoStore = useUserInfo();
const { goToLogin, isLogin } = initDetailsStates();
const { isPc } = useScreen();

const detail = computed(() => detailsStore.detail);
const isPublish = computed(() => detail.value.status === 'published');
const isMyLike = computed(() => detail.value.userAction?.like > 0);
const likeCount = computed(() => detail.value.actionSum?.like ?? 0);
const isMyFavorite = computed(() => detail.value.userAction?.favorite > 0);
const favoriteCount = computed(() => detail.value.actionSum?.favorite ?? 0);
const shareTitle = computed(() => detail.value.title);
const shareDescription = computed(() => detail.value.summary);
const isForSale = computed(() => detail.value.forSale);
const isAdmin = computed(() => userInfoStore.isAdmin);
const isCreator = computed(() => detail.value.isCreator);

const onActionTrigger = (type: string) => {
  detailsStore.handleToolStatusClick(type);
};

function getUpdateTime(date: number) {
  if (date) {
    return moment(date * 1000);
  } else {
    return moment(Date.now());
  }
}

// 沉贴/恢复 相关
const downBtnVisiable = ref(false);
const isOnLine = ref(detail.value.mstatus !== 'down');
const isOnLineComputed = computed({
  get() {
    return isOnLine.value;
  },
  set(val) {
    isOnLine.value = val;
  },
});
function handleVisiableChange(bool: boolean) {
  if (!bool) {
    downBtnVisiable.value = false;
    return;
  }
  if (isPreview.value) {
    return;
  } else {
    downBtnVisiable.value = true;
  }
}
function onToggleFreeMaking() {
  if (isPreview.value) {
    return;
  }
  if (isOnLineComputed.value) {
    downMaking();
  } else {
    cancelDownMaking();
  }
}
async function downMaking() {
  try {
    const id = detail.value.id;
    const { data } = await downMakingApi(id);
    if (data.value.code === 0) {
      message.success('down success!');
      isOnLine.value = false;
    } else {
      message.error(data.value?.message || 'Unknown system error!');
    }
  } catch (err: any) {
    message.error(err);
    console.log('downMaking-err :>> ', err);
  }
}
async function cancelDownMaking() {
  try {
    const id = detail.value.id;
    const res = await recoverMakingApi.fetch({ id });
    if (res.code === 0) {
      message.success('cancel down success!');
      isOnLine.value = true;
    } else {
      message.error(res?.message || 'Unknown system error!');
    }
  } catch (err) {
    console.log('cancelDownMaking-err :>> ', err);
  }
}

// 举报相关
const isReported = ref(false);
async function getReportStatus() {
  if (isPreview.value) {
    return;
  }

  if (!isLogin.value) {
    return;
  }

  const params = {
    objectId: detail.value.id,
    objectType: detail.value.type,
  };
  try {
    const { data } = await getReportStatusApi(params);
    if (!data?.value?.data) {
      isReported.value = false;
      return;
    }
    const { status } = data.value.data;
    isReported.value = ['todo', 'approved'].includes(status);
  } catch (err) {
    console.log('err :>> ', err);
  }
}
const reportModalRef = ref();
function openReportModal() {
  if (!isLogin.value) {
    goToLogin();
    return;
  }
  // 如果已举报
  if (isReported.value) {
    message.success({
      content: h('span', {
        innerHTML: `Thank you for letting us know. Your report is submitted.
        ${isPc.value ? '<br />' : ''}
        We'll address your concerns within 3 business days.`,
        style: {
          display: 'flex',
          textAlign: 'left',
        },
      }),
      key: 'report',
      class: 'content-detail-message-success',
    });
    return;
  }
  reportModalRef.value.open(detail.value);
}

// 下架/上架相关
const isCaseOn = ref(detail.value.mstatus === MStatus.OnShelves);
const isCaseOnComputed = computed({
  get: () => isCaseOn.value,
  set: (val) => {
    isCaseOn.value = val;
  },
});
const toggleShelfRef = ref();
async function onToggleDetail() {
  if (isCaseOnComputed.value) {
    toggleShelfRef.value.open(detail.value);
  } else {
    try {
      const { data } = await onShelvesMakingApi({
        id: detail.value.id,
      });
      if (data?.value?.code === 0) {
        message.success('unlist success!');
        isCaseOnComputed.value = true;
      }
    } catch (err) {
      console.log('toggle-shelf-ref-err :>> ', err);
    }
  }
}

const { ga4ShareClick } = useSensors();
const cb = (ev: any) => {
  // TODO: 获取作者名称
  ga4ShareClick({
    content_id: detail.value.id,
    content_name: detail.value.title,
    publish_time: detail.value.createdAt,
    author_name: '',
    scene_name: isOpenFromStudioEditor() ? SceneName.EDITORS : SceneName.ATOMM,
    page_name: PageName.DETAILS_PAGE,
    block_name: '',
    service_type: detail.value.type,
    share_type: ev.title,
  });
};
getReportStatus();
const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true;
});
</script>

<style lang="less" scoped></style>
