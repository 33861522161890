<template>
  <a-modal :open="visible" width="800px" :footer="false" :mask-closable="false" wrap-class-name="select-display-modal" @cancel="close">
    <div class="rounded-xl bg-[#fff] shadow">
      <p class="mb-0 p-4 text-level-1 font-semibold">{{ title }}</p>
      <div class="select-canvas-modal pb-3">
        <client-only v-if="visible">
          <canvas-m-canvas-list
            class="px-3"
            :editor-store="editorStore"
            :project-store="projectStore"
            :loading="loading"
            :canvas-list="canvasList"
            :current-id="currentId"
            :is-disabled="true"
            :current-select-display="currentSelectDisplay"
            @close="close"
          />
        </client-only>

        <div class="ml-8 mt-7 pr-8">
          <div class="mb-2 text-text-primary">
            <div class="flex items-center">
              <div class="">
                <icon-font type="icon-Laser" class="relative top-[1px] mr-2 text-[20px]" />
                <!-- 光线类型- 加工模式- 加工类型 -->
                <span v-if="setting.laserName" class="line-after">{{ setting.laserName }}</span>
                <span class="line-after">{{ setting.modeName }}</span>
                <span>{{ setting.processingTypeName }}</span>
              </div>
              <div v-if="setting.materialOfficial" class="line-before">
                <img
                  v-if="setting.materialOfficial?.imgUrl"
                  :src="getOssProcessedImage(setting.materialOfficial?.imgUrl, 'm_fill,w_24,h_24', 'webp')"
                  class="mr-2 h-[24px] w-[24px]"
                />
                <icon-font v-else type="icon-Consumables" class="relative top-[2px] mr-2 text-[20px]" />
                <span>{{ setting.materialOfficial?.name?.en }}</span>
              </div>
            </div>
            <div class="mt-4 flex flex-wrap gap-1">
              <span
                v-for="(val, key, i) of setting.parameter"
                :key="key"
                :class="[i < Object.keys(setting.parameter).length - 1 ? 'line-after' : '']"
              >
                {{ capFirst(String(key)) }}: {{ val }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex h-10 items-center rounded-b-[12px] bg-background-2 px-6">
        <icon-font type="icon-file-line" class="text-[16px]" />
        <span class="mx-2 line-clamp-1 flex-1">{{ file.name }}</span>
        <span>
          XTool&nbsp;{{ setting.deviceId }}
          {{ setting.powerName ? `| ${setting.powerName}` : '' }}
        </span>
      </div>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
// import { useProjectStore } from '@/stores/project';
import { capFirst } from '@/utils/fns/convert.js';
type Props = {
  visible: boolean;
  file: any;
  setting: any;
  title: string;
};
const props = defineProps<Props>();
type emitsType = {
  (event: 'update:visible', value: boolean): void;
};
const emit = defineEmits<emitsType>();
const close = () => {
  canvasList.value = [];
  currentId.value = '';
  currentSelectDisplay.value = [];
  emit('update:visible', false);
};

const editorStore = ref(null) as any;
const canvasList = ref([] as any);
const currentId = ref('');

const initEditor = async () => {
  const { useEditorStore } = await import('@/stores/editor');
  editorStore.value = useEditorStore();
  editorStore.value.initEditor();
};
watch(
  () => editorStore.value?.currentId,
  () => {
    canvasList.value = editorStore.value?.canvasList;
    currentId.value = editorStore.value?.currentId;
  },
  { deep: true },
);
const projectStore = ref(null) as any;
const loading = computed(() => projectStore.value?.projectLoading);
watch(
  () => loading.value,
  () => {
    if (!loading.value && props.visible === true) {
      setTimeout(() => {
        const id = props.setting.existCanvas[0].canvasId;
        editorStore.value.editorChangeCanvas(id);
        changeSelect();
      }, 0);
    }
  },
);

const initFile = async () => {
  const { useProjectStore } = await import('@/stores/project');
  projectStore.value = useProjectStore();
  projectStore.value.projectOpenByUrl({
    url: props.file.url,
    name: props.file.name,
  });
  initEditor();
};

// 所有选择元素id，调用xcs选择ids
const currentSelectDisplay = ref([] as any[]);

// 根据setting选择情况，更新画布选择情况
const changeSelect = () => {
  const ids = [] as any[];
  const existCanvas = props.setting.existCanvas;
  existCanvas.forEach((canvas: any) => {
    const arr = JSON.parse(JSON.stringify(canvas.displayIds));
    ids.push(...arr);
  });
  currentSelectDisplay.value = ids;
  setTimeout(() => {
    editorStore.value.canvas?.selectSpecDisplays(ids);
  }, 0);
};

onMounted(() => {
  if (props.visible === true) {
    initFile();
  }
});
watch(
  () => props.visible,
  async () => {
    if (props.visible === true) {
      initFile();
    } else {
      editorStore.value.destroyEditor();
    }
  },
);
</script>
<style lang="less" scoped>
.select-canvas-modal {
  .title {
    color: #212121;
    font-size: 30px;
    font-weight: theme('fontWeight.semibold');
    line-height: 38px; /* 126.667% */
  }
  .file-name {
    color: #555;

    font-size: 18px;
    font-weight: theme('fontWeight.semibold');
    line-height: 26px;
    margin: 8px 0 16px;
  }
  .tips {
    text-align: center;
    gap: 8px;
    background: theme('colors.background.4');
    padding: 2px 20px;
    .highlight {
      color: theme('colors.status.alert');
      font-weight: theme('fontWeight.bold');
    }
  }
}
.line-after {
  position: relative;
  margin-right: 8px;
  padding-right: 8px;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 16px;
    background: rgba(0, 0, 0, 0.1);
  }
}
.line-before {
  position: relative;
  margin-left: 8px;
  padding-left: 24px;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 16px;
    background: rgba(0, 0, 0, 0.1);
  }
}
@media screen and (max-width: 767px) {
  .select-canvas-modal {
    .title {
      font-size: 18px;
      line-height: 18px;
    }
    .file-name {
      font-size: 14px;
      line-height: 14px;
      margin: 8px 0;
    }
  }
}
</style>
<style lang="less">
.select-display-modal {
  .ant-modal {
    top: 50px;
  }
}
@media screen and (max-height: 710px) {
  .select-display-modal {
    .ant-modal {
      top: 10px;
    }
  }
}
.select-display-modal {
  .ant-modal-body {
    padding: 0;
  }
}
</style>
