<template>
  <div id="docx-container" class="docx-preview">
    <div class="border-0 border-t border-solid border-[rgba(0,0,0,0.1)] py-10">
      <div class="flex justify-center">
        <div class="flex h-[400px] w-[310px] items-center justify-center overflow-hidden rounded-2xl text-[0]">
          <img class="block w-full rounded-2xl object-cover" :src="WordCover" alt="" />
        </div>
      </div>
    </div>

    <div class="flex justify-center gap-8 border-0 border-t border-solid border-[rgba(0,0,0,0.1)] py-8">
      <div class="flex cursor-pointer gap-2 text-uni-1" @click="onDownload">
        <icon-font class="text-[24px]" type="icon-a-30-Download" />
        <span class="text-level-3 font-semibold">Download</span>
      </div>
      <span class="inline-flex w-[1px] bg-[rgba(0,0,0,0.1)]"></span>
      <div class="flex cursor-pointer gap-2 text-uni-1" @click="open">
        <df-spin v-if="isSpinning" />
        <icon-font v-if="!isSpinning" class="text-[24px]" type="icon-eye-line" />
        <span class="text-level-3 font-semibold">Preview</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import WordCover from '@/assets/img/file-preview/word_cover.png';
import { renderAsync } from 'docx-preview';

type PropsType = {
  fileUrl: string;
  name?: string;
};
const props = defineProps<PropsType>();

const { $client } = useNuxtApp();
const { isLogin } = useLoginStatus();

const isSpinning = ref(false);
// 打开
async function open() {
  if (isSpinning.value) {
    return;
  }
  try {
    isSpinning.value = true;
    const docxBlob = await fetchDocxFile(props.fileUrl);
    const htmlElement = await convertDocxToHtml(docxBlob);
    convertCanvasToPdf(htmlElement);
  } catch (error) {
    console.error('open-Error:', error);
  } finally {
    isSpinning.value = false;
  }
}

// 1. 获取文件
async function fetchDocxFile(url: string) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.blob();
}
// 2. 使用 docx-preview 将 .docx 转换为 HTML
async function convertDocxToHtml(blob: Blob) {
  const element = document.createElement('div');
  await renderAsync(blob, element, undefined, {
    className: 'docx',
    inWrapper: true,
    ignoreWidth: false,
    ignoreHeight: true,
    ignoreFonts: true,
    breakPages: true,
    ignoreLastRenderedPageBreak: true,
    experimental: true,
    trimXmlDeclaration: true,
    useBase64URL: true,
    renderChanges: true,
    renderHeaders: true,
    renderFooters: true,
    renderFootnotes: true,
    renderEndnotes: true,
    renderComments: true,
    debug: false,
  });
  return element;
}

// 3. 把 html 写入到新窗口
function convertCanvasToPdf(el: HTMLDivElement) {
  if (isElectronXcs()) {
    // 创建模态框容器
    const modal = document.createElement('div');
    modal.style.cssText =
      'position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,0.8);z-index:9999;display:flex;flex-direction:column;justify-content:center;align-items:center;overflow:hidden;';
    // 创建内容容器
    const contentContainer = document.createElement('div');
    contentContainer.style.cssText = 'position:relative;width:90%;height:90%;background:#fff;border-radius:4px;overflow:hidden;';
    // 添加关闭按钮
    const closeBtn = document.createElement('button');
    closeBtn.innerText = 'close';
    closeBtn.style.cssText =
      'position:absolute;top:10px;right:10px;z-index:10001;padding:5px 15px;background:#f44336;color:white;border:none;border-radius:4px;cursor:pointer;font-weight:bold;';
    closeBtn.onclick = () => {
      document.body.removeChild(modal);
    };
    // 创建内容容器
    const contentArea = document.createElement('div');
    contentArea.style.cssText = 'width:100%;height:100%;overflow:auto;box-sizing:border-box;';
    contentArea.classList.add('docx-preview-box');
    contentArea.innerHTML = el.innerHTML;
    // 组装DOM结构
    contentContainer.appendChild(closeBtn);
    contentContainer.appendChild(contentArea);
    modal.appendChild(contentContainer);
    document.body.appendChild(modal);
    return;
  }

  const newWindow = window.open('', '_blank');
  if (!newWindow) {
    return;
  }
  newWindow.document.write(`<html><head><style>html,body{margin:0;padding:0;}</style><title>${props.name}</title></head><body>`);
  newWindow.document.write(el.innerHTML);
  newWindow.document.write('</body></html>');
  newWindow.document.close();
}

// 下载
function download() {
  onDownload();
}

function onDownload() {
  if (!isLogin.value) {
    $client.login();
    return;
  }
  const link = document.createElement('a');
  link.setAttribute('download', props.name || 'file');
  link.setAttribute('href', props.fileUrl);
  link.click();
  link.remove();
}

defineExpose({
  open,
  download,
});
</script>

<style lang="less">
// 修改弹窗中docx-wrapper的背景色
.docx-preview-box > .docx-wrapper {
  background-color: #fff;
}
</style>
