<template>
  <div>
    <NuxtLink :to="targetLink" target="_blank" class="bundle-card rounded-xl">
      <div class="image relative">
        <RatioBox>
          <div class="img-box relative h-full w-full overflow-hidden rounded">
            <img class="h-full w-full" :src="getOssProcessedImage(cover, 'm_fill,w_800,h_600', 'webp')" :alt="bundleItem.title" />
          </div>
        </RatioBox>
        <div v-if="hasBundleRight" class="tag absolute left-2 top-2 z-[2] flex h-6 items-center gap-1 rounded bg-[#00C767] px-2 text-[#fff]">
          <icon-font class="text-[16px]" type="icon-Purchased" />
          <span class="text-level-7">Purchased</span>
        </div>
        <div
          v-if="!hasRight"
          class="price absolute bottom-0 right-0 z-[2] flex h-7 items-center rounded-tl px-2 text-level-7 font-bold text-text-primary"
        >
          {{ price }}
        </div>
      </div>
    </NuxtLink>
    <div class="title two-line-ellipsis h-[68px] rounded-b pb-4 pt-2" :title="bundleItem.title">
      <div class="two-line-ellipsis text-level-4 font-semibold text-text-primary">
        {{ bundleItem.title }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type PropsType = {
  bundleItem: any;
  hasRight: boolean;
};
const props = withDefaults(defineProps<PropsType>(), {
  bundleItem: () => ({}),
  hasRight: false,
});

const cover = computed(() => props.bundleItem.blockCover || props.bundleItem.cover);
const price = computed(() => {
  const price = props.bundleItem.origPrice.toFixed(2);
  return `$${price}`;
});
const hasBundleRight = computed(() => {
  if (props.hasRight) {
    return false;
  }
  return props.bundleItem.hasRight;
});
const targetLink = computed(() => {
  return `/${props.bundleItem.type}/${props.bundleItem.id}`;
});
</script>

<style lang="less" scoped>
.img-box {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 1;
  }
}
.img-box:hover {
  &::after {
    opacity: 1;
  }
}

.price {
  background: rgba(237, 237, 237, 0.8);
  backdrop-filter: blur(7.5px);
}
</style>
