<template>
  <div class="pdf-preview-wrapper flex flex-col bg-[#fefeff]">
    <a-spin :spinning="option.pageRendering">
      <div ref="pdfContainerRef" class="border-0 border-t border-solid border-[rgba(0,0,0,0.1)] py-10">
        <canvas
          id="pdf-canvas"
          ref="firstCanvasRef"
          class="m-auto block rounded-2xl"
          :class="{
            'shadow-md': !option.pageRendering,
          }"
        ></canvas>
      </div>

      <div class="flex justify-center gap-8 border-0 border-t border-solid border-[rgba(0,0,0,0.1)] py-8">
        <div class="flex cursor-pointer gap-2 text-uni-1" @click="onDownload">
          <icon-font class="text-[24px]" type="icon-a-30-Download" />
          <span class="text-level-3 font-semibold">Download</span>
        </div>
        <span class="inline-flex w-[1px] bg-[rgba(0,0,0,0.1)]"></span>
        <div class="flex cursor-pointer gap-2 text-uni-1" @click="openPdf">
          <df-spin v-if="isSpinning" />
          <icon-font v-if="!isSpinning" class="text-[24px]" type="icon-eye-line" />
          <span class="text-level-3 font-semibold">Preview</span>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script setup lang="ts">
import { usePdfJs } from '@/components/file-preview/pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';

type PropsType = {
  fileUrl: string;
  name?: string;
};
const props = defineProps<PropsType>();

const { getPdfJs } = usePdfJs();
const { $client } = useNuxtApp();
const { isLogin } = useLoginStatus();

const option = reactive({
  current: 1,
  scale: 0.8,
  pageRendering: false,
});
let pdfDoc: null | PDFDocumentProxy = null;
const totalPages = ref(0);
const firstCanvasRef = ref<HTMLCanvasElement | null>(null);
const ctx = ref<CanvasRenderingContext2D | null>(null);

// 下载PDF
function onDownload() {
  if (!isLogin.value) {
    $client.login();
    return;
  }
  const link = document.createElement('a');
  link.setAttribute('download', props.name || 'file');
  link.setAttribute('href', props.fileUrl);
  link.click();
  link.remove();
}

const isSpinning = ref(false);
// 打开PDF
const openPdf = () => {
  if (isSpinning.value) {
    return;
  }
  isSpinning.value = true;
  const xhr = new XMLHttpRequest();
  xhr.open('GET', props.fileUrl, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (this.status === 200) {
      const blob = this.response;
      const url = URL.createObjectURL(blob);
      if (isElectronXcs()) {
        createInPageViewer(url);
      } else {
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('target', '_blank');
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
      }
      isSpinning.value = false;
    }
  };
  xhr.onerror = function () {
    isSpinning.value = false;
  };
  xhr.send();
};

// 创建页内PDF查看器（使用弹窗形式，并且用pdf-dist渲染完整的PDF）
async function createInPageViewer(url: string) {
  // 创建模态框容器
  const modal = document.createElement('div');
  modal.style.cssText =
    'position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,0.8);z-index:9999;display:flex;flex-direction:column;justify-content:center;align-items:center;overflow:hidden;';
  // 创建内容容器
  const contentContainer = document.createElement('div');
  contentContainer.style.cssText = 'position:relative;width:90%;height:90%;background:#fff;border-radius:4px;overflow:hidden;';
  // 添加关闭按钮
  const closeBtn = document.createElement('button');
  closeBtn.innerText = 'close';
  closeBtn.style.cssText =
    'position:absolute;top:10px;right:10px;z-index:10001;padding:5px 15px;background:#f44336;color:white;border:none;border-radius:4px;cursor:pointer;font-weight:bold;';
  closeBtn.onclick = () => {
    document.body.removeChild(modal);
    URL.revokeObjectURL(url);
  };
  // 创建PDF容器
  const pdfContainer = document.createElement('div');
  pdfContainer.style.cssText = 'width:100%;height:100%;overflow:auto;padding:20px;box-sizing:border-box;';
  // 添加加载中提示
  const loadingElement = document.createElement('div');
  loadingElement.innerText = 'loading...';
  loadingElement.style.cssText = 'position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);font-size:18px;color:#333;';
  // 组装DOM结构
  contentContainer.appendChild(closeBtn);
  contentContainer.appendChild(loadingElement);
  contentContainer.appendChild(pdfContainer);
  modal.appendChild(contentContainer);
  document.body.appendChild(modal);
  try {
    const pdfjsLib = await getPdfJs();
    const loadingTask = pdfjsLib.getDocument(url);
    const pdfDoc = await loadingTask.promise;
    // 创建一个容器来存放所有页面
    const pagesContainer = document.createElement('div');
    pagesContainer.style.cssText = 'display:flex;flex-direction:column;align-items:center;gap:20px;';
    // 逐页渲染
    for (let pageNum = 1; pageNum <= totalPages.value; pageNum++) {
      // 获取页面
      const page = await pdfDoc.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1.5 });
      // 为每页创建容器
      const pageContainer = document.createElement('div');
      pageContainer.style.cssText = 'margin-bottom:10px;box-shadow:0 2px 5px rgba(0,0,0,0.1);overflow:auto;';
      // 创建canvas元素
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      canvas.style.display = 'block';
      // 渲染PDF到canvas
      const renderContext = {
        canvasContext: context as CanvasRenderingContext2D,
        viewport,
      };
      await page.render(renderContext).promise;
      pageContainer.appendChild(canvas);
      pagesContainer.appendChild(pageContainer);
    }
    pdfContainer.appendChild(pagesContainer);
    // 移除加载提示
    contentContainer.removeChild(loadingElement);
  } catch (error) {
    console.error('Error rendering PDF:', error);
    pdfContainer.innerHTML = '<div style="text-align:center;padding:20px;color:red;">can not load pdf</div>';
  }
}

let pdfjsLib = null;
// 加载PDF文档
async function loadPdf() {
  try {
    pdfjsLib = await getPdfJs();
    // console.log('pdfjsLib :>> ', pdfjsLib);
    option.pageRendering = true;
    const loadingTask = pdfjsLib.getDocument({ url: props.fileUrl });
    pdfDoc = await loadingTask.promise;
    totalPages.value = pdfDoc.numPages;
    await renderPage(option.current);
  } catch (err) {
    console.log(err);
  } finally {
    option.pageRendering = false;
  }
}

// 渲染PDF页面
const renderPage = async (pageNumber: number) => {
  if (!pdfDoc) {
    return;
  }
  try {
    const page = await pdfDoc.getPage(pageNumber);
    const viewport = page.getViewport({ scale: option.scale });
    if (!firstCanvasRef.value) {
      return;
    }
    firstCanvasRef.value.width = viewport.width;
    firstCanvasRef.value.height = viewport.height;
    ctx.value = firstCanvasRef.value.getContext('2d');
    if (!ctx.value) {
      return;
    }
    const renderContext = {
      canvasContext: ctx.value,
      viewport: viewport,
    };
    page.render(renderContext);
  } catch (err) {
    console.log('err :>> ', err);
  }
};

const open = () => {
  openPdf();
};
const download = () => {
  onDownload();
};

onMounted(() => {
  loadPdf();
});

defineExpose({
  open,
  download,
});
</script>

<style lang="less" scoped></style>
