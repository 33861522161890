<template>
  <div
    :class="'mt-4 flex items-center justify-between rounded border border-solid border-[rgba(26,26,26,0.1)] bg-[#fff] px-4 py-2 pad:px-2 mobile:px-2'"
  >
    <div class="flex items-center justify-center gap-2">
      <div class="relative inline-flex h-5 w-5">
        <img :src="ActivityIcon" class="absolute left-[-2px] top-0 w-[23px]" />
      </div>
      <span class="flex-1 text-level-4 text-text-primary pad:text-level-7 mobile:text-level-7">In contest {{ activity?.title }}</span>
    </div>
    <div class="flex cursor-pointer items-center justify-center gap-1" @click="onClickMore">
      <span :class="'whitespace-nowrap text-level-7 font-semibold text-text-secondary hover:text-text-primary pad:text-level-7 mobile:text-level-7'">
        See more
      </span>
      <icon-font class="text-[12px]" type="icon-a-30-arrow-right-s-line" />
    </div>
  </div>
</template>

<script setup lang="ts">
import ActivityIcon from '~/assets/img/project-detail/activity.png';
import { type ItemType } from '~/types';

type PropsType = {
  detail: ItemType;
};
const props = defineProps<PropsType>();

const activity = computed(() => props.detail.activity);

const { sensorActivityEntryClick } = useSensors();

const onClickMore = () => {
  const { id, title, extra } = activity.value;

  sensorActivityEntryClick({
    content_name: title,
    content_id: id,
  });
  createAlink(extra.pageUrl, '_blank');
};
</script>

<style lang="less" scoped></style>
