<template>
  <div class="file-infos mb-8">
    <h3 class="mb-0 text-level-2 font-semibold text-text-primary pad:text-level-2 mobile:text-level-2">Digital Downloads</h3>

    <div class="mt-4 flex flex-wrap gap-2">
      <div v-for="item in fileInfos" :key="item.type" class="flex items-center rounded border border-solid border-[rgba(0,0,0,0.1)] p-2">
        <icon-font :type="getIconName(item.type)" class="text-[24px]" />
        <span class="ml-1.5 text-14-regular">{{ item.type }} *{{ item.cnt }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type Props = {
  detail: any;
};
const props = defineProps<Props>();

const fileInfos = computed(() => props.detail.fileInfos ?? []);

const TypeIconMap: Record<string, string> = {
  ZIP: 'icon-zip',
  PDF: 'icon-pdf',
  XCS: 'icon-a-XCSFile',
  DXF: 'icon-unknown',
  JPG: 'icon-picture',
  TXT: 'icon-text',
  PNG: 'icon-PNG',
};

const getIconName = (type: string): string => {
  return TypeIconMap[type] || 'icon-tongyong';
};
</script>

<style lang="less" scoped></style>
