import { type DetailProvide, AuditStatus } from '@/components/project-details/types';
import { useDetailsStore } from '@/stores/details';
import { useUserInfo } from '@/stores/user';
import { computed } from 'vue';
import type { CONTENT_TYPE } from '~/types';

export function useActions(props: { auditStatus: AuditStatus | undefined }) {
  const { sensorsContentDetailClick, sensorsxOpenInBrowser } = useSensors();
  const { isPreview, audit } = inject('detail-common') as DetailProvide;
  const userInfoStore = useUserInfo();
  const detailsStore = useDetailsStore();
  const detail = computed(() => detailsStore.detail);
  const contentType = computed(() => detail.value.type);
  const isCreator = computed(() => detail.value.isCreator);
  const isAdmin = computed(() => userInfoStore.isAdmin);
  // 是否已在线售卖
  const isOnlineForSale = computed(() => detail.value.mstatus === 'on_shelves');
  // 是否审核中
  const isUnderReview = computed(() => detail.value?.latestAudit?.status === 'todo');
  const showEdit = computed(() => {
    if (!audit) {
      // 若不是审核状态，管理员和作者都可以编辑
      return isAdmin.value || isCreator.value;
    } else {
      if (!props.auditStatus) {
        return false;
      }
      // 若是审核状态，只有拒绝和下架时可编辑
      if ([AuditStatus.OffShelves, AuditStatus.Reject].includes(props.auditStatus)) {
        return true;
      } else {
        return false;
      }
    }
  });
  const editable = computed(() => {
    if (isAdmin.value) {
      return true;
    }
    return !isUnderReview.value;
  });
  const showDel = computed(() => {
    if (isAdmin.value) {
      return true;
    }
    // 如果在线售卖，作者也不能删除，只有管理员可以删除
    if (isCreator.value && !isOnlineForSale.value) {
      return true;
    }
    return false;
  });
  const hasRightOptions = computed(() => {
    return !isPreview && (showEdit.value || showDel.value);
  });
  const showCopy = computed(() => isCreator.value);
  const showOpenInBrowser = computed(() => isElectron());

  const handleEditClick = () => {
    const type = contentType.value as CONTENT_TYPE;
    const id = detail.value?.id as number;
    detailsStore.editMaking(type, id, audit);
  };

  function handleDeleteClick() {
    detailsStore.deleteMakingById(detail.value?.id as number);
  }
  const handleUseTemplateAction = () => {
    const mode = detail.value.forSale ? 'sale' : 'free';
    const { id: content_id, title: content_name, type: service_type } = detail.value;
    detailsStore.useTemplateById(detail.value?.id as number, mode);
    sensorsContentDetailClick({
      content_id,
      content_name,
      service_type,
      content_type: mode,
    });
  };

  // 浏览器打开
  const handleOpenInBrowser = () => {
    sensorsxOpenInBrowser();
    window.open(document.location.href, '_blank');
  };

  return {
    isPreview,
    audit,
    showEdit,
    showDel,
    editable,
    showCopy,
    showOpenInBrowser,
    handleEditClick,
    handleDeleteClick,
    handleUseTemplateAction,
    handleOpenInBrowser,
    hasRightOptions,
  };
}
