import { initStates, isValidURL, useFileMethods } from '@/components/project-details/hook';
import { useDetailsStore } from '@/stores/details';
import { storeToRefs } from 'pinia';
import PictureCoverPng from '~/assets/img/project-detail/picture_cover.png';
import TxtCoverPng from '~/assets/img/project-detail/txt_cover.png';
import UnknownCoverPng from '~/assets/img/project-detail/unknown_cover.png';
import XcsCoverPng from '~/assets/img/project-detail/xcs_cover.png';
import ZipCoverPng from '~/assets/img/project-detail/zip_cover.png';
import { CONTENT_TYPE, OpenFrom } from '~/types';
import { SceneName } from '~/types/ga4';

const STUDIO_SITE = import.meta.env.VITE_ATOMM_STUDIO_SITE;
type OpenInStudioOpts = {
  position?: 'up' | 'bottom' | 'in_file';
};
type DownloadClickOpts = {
  position?: 'up' | 'bottom' | 'in_file';
};
const imgReg = /(gif|svg|jpg|jpeg|png|bmp|webp)/;
const zipReg = /(zip|rar|7z|tar|gz|bz2|xz)/;
const docReg = /(txt|text|pdf)/;
// 支持在studio打开的文件, 包括xcs、svg、jpg、jpeg、png、dxf、gif、bmp、webp
const studioFileReg = /(xcs|svg|jpg|jpeg|png|dxf|gif|bmp|webp)/;

// 处理文件的 computed
export const attachFilesComputed = (files: any, isXthings: boolean) => {
  const newFiles = JSON.parse(JSON.stringify(files || []));
  // 文件标记
  newFiles.forEach((file: any) => {
    const suffix = file.name.split('.')?.pop()?.toLowerCase();
    switch (suffix) {
      // 支持在studio打开的文件
      case studioFileReg.test(suffix) && suffix:
        file.isStudioFile = true;
      // xcs 文件
      case 'xcs':
        if (file.extra?.cover) {
          file.isXcsFile = true;
          file.fileCover = file.extra?.cover;
        } else {
          file.xcsFileNoCover = true;
          file.fileCover = XcsCoverPng;
        }
        // xthings要显示 设备+激光头，其余只显示设备
        if (isXthings) {
          const deviceName = file.extra?.deviceName;
          const powerName = file.extra?.powerName;
          file.xcsTags = powerName ? `${deviceName} - ${powerName}` : deviceName;
        } else {
          file.xcsTags = file.extra?.extId;
        }
        file.isXcsFile = true;
        break;
      // 图片
      case imgReg.test(suffix) && suffix:
        file.fileCover = PictureCoverPng;
        file.isImgFile = true;
        break;
      // 压缩包
      case zipReg.test(suffix) && suffix:
        file.fileCover = ZipCoverPng;
        break;
      // 文档
      case docReg.test(suffix) && suffix:
        file.fileCover = TxtCoverPng;
        break;
      // dxf
      case 'dxf':
        file.isDxfFile = true;
      default:
        file.fileCover = UnknownCoverPng;
        break;
    }
  });
  // 把.xcs文件排在最前面
  newFiles.sort((a: any, b: any) => {
    const aIsXcs = a.name.endsWith('.xcs');
    const bIsXcs = b.name.endsWith('.xcs');
    if (aIsXcs && !bIsXcs) {
      return -1; // a是.xcs文件，b不是，a排前面
    }
    if (!aIsXcs && bIsXcs) {
      return 1; // a不是.xcs文件，b是，b排前面
    }
    // 如果两个都是.xcs或两个都不是.xcs，按名称字母顺序排序
    return a.name.localeCompare(b.name);
  });
  return newFiles;
};

// 处理文件名成一个对象
export const getFileNameObject = (fileName: string) => {
  const arr = fileName?.split('.');
  // 文件后缀
  const ext = arr.pop();
  // 文件名
  const name = arr.join('.');
  if (name.length <= 4) {
    return {
      first: name,
      ext: `.${ext}`,
    };
  }
  const first = name.slice(0, name.length - 4);
  const last = name.slice(name.length - 4, name.length);
  return {
    first,
    last,
    ext: `.${ext}`,
  };
};

// 文件处理逻辑
export const useFile = () => {
  const detailsStore = useDetailsStore();
  const route = useRoute();
  const { detail } = storeToRefs(detailsStore);
  const { goToLogin, isLogin, getPassportToken, callXcs, isCallingXcs } = initStates();
  const localToken = useMiddleCookie('utoken');
  const { downloadFile } = useFileMethods();
  const { ga4OpenInStudioClick, ga4DownloadClick } = useSensors();

  const ga4Params = window?.df_shen?.getParams();
  const isCollapsed = ref(true);
  const collapsedBtnRef = ref<HTMLElement | null>(null);
  const isDownloadLoading = ref(false);
  const isDownLoadAllLoading = ref(false);
  const importToCanvasLoading = ref(false);
  const p = ref(0);
  const currentFileUid = ref('');
  const filesModalRef = ref();

  const isForSale = computed(() => detail.value.forSale);
  const isXthings = computed(() => detail.value.type === CONTENT_TYPE.XTHINGS);
  const projectTitle = computed(() => detail.value.title);
  const isFileAll = computed(() => {
    return !isAppWebkit() && moreThanOneFile.value;
  });
  const downloadBtnTitle = computed(() => {
    if (isDownloadLoading.value || isDownLoadAllLoading.value) {
      return `Downloading`;
    }
    return isFileAll.value ? 'Download All' : 'Download';
  });
  const newAttachFiles = computed(() => attachFilesComputed(detail.value?.attachFiles, isXthings.value));
  const moreThanOneFile = computed(() => newAttachFiles.value.length > 1);
  const displayFiles = computed(() => {
    const files = newAttachFiles.value;
    return isCollapsed.value ? files.slice(0, 2) : files;
  });
  const xcsFileList = computed(() => {
    const attachFiles = detail.value?.attachFiles || [];
    return attachFiles.filter((item: any) => {
      const suffix = item.name?.split('.')?.pop()?.toLowerCase();
      return suffix === 'xcs';
    });
  });
  // 提取支持在studio打开的文件列表
  const studioFileList = computed(() => {
    const attachFiles = newAttachFiles.value || [];
    return attachFiles.filter((item: any) => {
      const suffix = item.name?.split('.')?.pop()?.toLowerCase();
      return studioFileReg.test(suffix);
    });
  });
  // 是否显示在studio打开按钮
  const isOpenInStudioBtnShow = computed(() => {
    // 如果在 pc app 中，则不显示
    if (isElectron()) {
      return false;
    }
    // 如果在移动app中，则不显示
    if (isAppWebkit()) {
      return false;
    }
    return studioFileList.value.length > 0;
  });
  const isOpenIconShow = computed(() => {
    // ipad浏览器中，不显示
    if (isIpad()) {
      return false;
    }
    // 如果不在 pc app 中，则不显示
    if (!isElectron()) {
      return false;
    }
    return true;
  });
  const isOpenBtnShow = computed(() => {
    // 如果没有xcs文件，则不显示
    if (xcsFileList.value.length <= 0) {
      return false;
    }
    // 如果在ipad浏览器中，则不显示
    if (isIpad()) {
      return false;
    }
    // 如果在移动app中 且 xcs文件数量大于1，则不显示(移动app中，xcs文件只能打开一个)
    if (isAppWebkit() && xcsFileList.value.length > 1) {
      return false;
    }
    // 如果不在 pc app 中，则不显示
    if (!isElectron()) {
      return false;
    }
    return true;
  });
  // 是否从xcs画布入口打开
  const isInPcXcs = computed(() => route.query.openFrom === OpenFrom.Xcs && isInPCApp());
  // 若当前素材为单一文件，且文件格式为位图或svg、dxf，则显示
  const isImportToCanvasBtnShow = computed(() => {
    const files = newAttachFiles.value;
    const file = files[0];
    return isInPcXcs.value && files.length === 1 && (file.isImgFile || file.isDxfFile);
  });
  // 是否有按钮组
  const hasButtonGroup = computed(() => {
    return newAttachFiles.value.length > 0 || isOpenBtnShow.value || isImportToCanvasBtnShow.value || isOpenInStudioBtnShow.value;
  });

  // 点击折叠按钮
  function onClickCollapse() {
    isCollapsed.value = !isCollapsed.value;
    if (isCollapsed.value) {
      nextTick().then(() => {
        window.scrollTo({
          top: (collapsedBtnRef.value?.offsetTop || 0) - 70,
        });
      });
    }
  }

  // 导入到画布
  const onImportToCanvas = () => {
    importOneToCanvas(newAttachFiles.value[0]);
  };
  function importOneToCanvas(file: any) {
    if (!file.isImgFile && !file.isDxfFile) {
      return;
    }

    importToCanvasLoading.value = true;
    const payload = {
      url: file.url,
      ext: file.name.replace(/^[\s\S]+?(?:\.(\w+)$)/, '$1').toUpperCase(),
      source: 'designs',
      traceInfo: {
        name: file.name,
        content_id: detail.value.id,
      },
    };
    window.parent.postMessage(
      {
        type: 'webCommandOpenImage',
        token: getUToken(),
        payload,
      },
      '*',
    );
  }
  // 下载
  function onDownloadClick(opts: DownloadClickOpts) {
    if (isFileAll.value) {
      downloadAll(opts);
    } else {
      downloadOne(newAttachFiles.value[0], opts);
    }
  }
  function downloadAll(opts: DownloadClickOpts) {
    if (!isLogin.value) {
      goToLogin();
      return;
    }

    // 下载全部 点击埋点
    const para = {
      file_name: 'download_all',
      content_id: detail.value.id,
      is_free: !isForSale.value,
      click_position: opts.position,
      latest_page_name: ga4Params?.latest_page_name || '',
      latest_block_name: ga4Params?.latest_block_name || '',
      latest_keyword: ga4Params?.latest_keyword || '',
      latest_scene_name: String(ga4Params?.latest_scene_name || ''),
      latest_contentid_algorithm_version: ga4Params?.latest_contentid_algorithm_version || '',
      latest_contentid_algorithm_type: ga4Params?.latest_contentid_algorithm_type || '',
    };
    ga4DownloadClick(para);

    try {
      isDownLoadAllLoading.value = true;
      // 提前检测下载地址是否可访问
      const url = detail.value.attachFiles[0]?.url as any;
      const urlObj = new URL(url);
      urlObj.searchParams.append('type_to_app', 'download');
      const urlString = urlObj.toString();
      const isValid = isValidURL(urlString);
      if (!isValid) {
        message.warning(`Download link expired. Please revisit template details and click 'Download' for a new link.`);
        isDownLoadAllLoading.value = false;
        return;
      }
    } catch (err) {
      console.log('download-zip-err :>> ', err);
      isDownLoadAllLoading.value = false;
    }

    const success = () => {
      isDownLoadAllLoading.value = false;
      p.value = 0;
      detailsStore.showLikeModal('zip', opts);
      handleDownloadCnt();
    };
    message.success(`File is downloading. It's large, so it may take a while. Please wait.`);
    downloadZip(detail.value.attachFiles, {
      fileName: detail.value.title,
      successFn: success,
      progressFn: (percent: number) => {
        p.value = +percent.toFixed(0);
      },
    });
  }
  async function downloadOne(file: any, opts: DownloadClickOpts) {
    if (!isLogin.value) {
      goToLogin();
      return;
    }

    // 检查是否断网
    if (!globalThis.navigator.onLine) {
      message.error('No internet connection detected. Please check your network settings and try again.');
      return;
    }

    // 单个下载 点击埋点
    const para = {
      file_name: file.name,
      content_id: detail.value.id,
      is_free: !isForSale.value,
      click_position: opts.position,
      latest_page_name: ga4Params?.latest_page_name || '',
      latest_block_name: ga4Params?.latest_block_name || '',
      latest_keyword: ga4Params?.latest_keyword || '',
      latest_scene_name: String(ga4Params?.latest_scene_name || ''),
      latest_contentid_algorithm_version: ga4Params?.latest_contentid_algorithm_version || '',
      latest_contentid_algorithm_type: ga4Params?.latest_contentid_algorithm_type || '',
    };
    ga4DownloadClick(para);

    await handleDownloadCnt();
    isDownloadLoading.value = true;
    currentFileUid.value = file.uid;
    downloadFile(
      file,
      () => {
        isDownloadLoading.value = false;
        currentFileUid.value = '';
        p.value = 0;
        nextTick(() => {
          detailsStore.showLikeModal(file.name, opts);
        });
      },
      () => {
        isDownloadLoading.value = false;
        currentFileUid.value = '';
        p.value = 0;
      },
      (percent: number) => {
        p.value = +percent.toFixed(0);
      },
    );
  }
  async function handleDownloadCnt() {
    if (!localToken.value) {
      return;
    }
    const passportToken = getPassportToken();
    if (!passportToken.value) {
      return;
    }
    detailsStore.triggerAction('download');
  }
  // 在xcs中打开
  function onOpenClick(entry?: 'up' | 'bottom') {
    if (xcsFileList.value.length > 1) {
      openAll();
    } else {
      openOne(xcsFileList.value[0]);
    }

    const { sensorsCreateYours } = useSensors();
    const isInXcs = isElectronXcs() || isAppWebkit();
    const p = {
      entry: entry || '',
      file_name: xcsFileList.value[0]?.name || '',
      content_id: detail.value.id,
      content_name: detail.value.title,
      service_type: detail.value.type,
      content_type: isForSale.value ? 'paid' : 'free',
      is_xcs: Boolean(isInXcs),
      latest_page_name: ga4Params?.latest_page_name,
      latest_block_name: ga4Params?.latest_block_name,
      latest_keyword: ga4Params?.latest_keyword,
      latest_scene_name: String(ga4Params?.latest_scene_name || ''),
      latest_contentid_algorithm_version: ga4Params?.latest_contentid_algorithm_version,
      latest_contentid_algorithm_type: ga4Params?.latest_contentid_algorithm_type,
    };
    sensorsCreateYours(p);
  }
  function openOne(file: any) {
    if (!isLogin.value) {
      goToLogin();
      return;
    }
    callXcs({
      type: 'webCommandOpenProject',
      token: localToken.value,
      url: file.url,
      name: file.name,
    });
  }
  function openAll() {
    if (!isLogin.value) {
      goToLogin();
      return;
    }
    if (isCallingXcs.value) {
      return;
    }
    const xcsFiles: any[] = xcsFileList.value.map((item: any) => ({ url: item.url, name: item.name }));
    const firstFile = xcsFiles[0];
    callXcs({
      type: 'webCommandOpenProject',
      token: localToken.value,
      url: firstFile.url,
      name: firstFile.name,
      makingId: detail.value.id,
    });
  }
  // 在studio打开
  function onOpenInStudio(opts?: OpenInStudioOpts) {
    if (studioFileList.value.length > 1) {
      if (!isLogin.value) {
        goToLogin();
        return;
      }
      filesModalRef.value?.open();
    } else {
      openOneInStudio(studioFileList.value[0], opts);
    }
  }
  function openAllInStudio(files: any[], opts?: OpenInStudioOpts) {
    detailsStore.triggerAction('download');
    triggerGa4(files[0]?.name, opts);

    if (!isOpenFromStudioEditor()) {
      files.forEach((file) => {
        openFileInStudioEditor(file);
      });
      return;
    }

    // 在studio editor中打开
    window.parent.postMessage(
      {
        type: 'webCommandOpenProject',
        token: localToken.value,
        files: files.map((file) => ({
          url: file.url,
          name: file.name,
        })),
        sourceId: detail.value.id,
      },
      '*',
    );
  }
  /**
   * 打开单个文件
   * 文件链接格式: https://studio.atomm.com/project/?source=designFindDetail&type=OSS&url=${url}
   * type 传参
   * XCS: type=OSS
   * DFX: type=dxfImport
   * SVG/JPG/JPEG/PNG: type=imageImport
   */
  function openOneInStudio(file: any, opts?: OpenInStudioOpts) {
    if (!isLogin.value) {
      goToLogin();
      return;
    }
    detailsStore.triggerAction('download');
    triggerGa4(file.name, opts);

    if (!isOpenFromStudioEditor()) {
      openFileInStudioEditor(file);
      return;
    }

    // 在studio editor中打开
    window.parent.postMessage(
      {
        type: 'webCommandOpenProject',
        token: localToken.value,
        files: [{ url: file.url, name: file.name }],
        sourceId: detail.value.id,
      },
      '*',
    );
  }

  function openFileInStudioEditor(file: any) {
    const suffix = file.name?.split('.')?.pop()?.toLowerCase();
    const type = suffix === 'xcs' ? 'OSS' : suffix === 'dxf' ? 'dxfImport' : 'imageImport';
    const url = `${STUDIO_SITE}/project/?source=designFindDetail&sourceId=${detail.value.id}&type=${type}&url=${btoa(file.url)}`;
    console.log('openInStudio :>> ', {
      file: file,
      url: url,
    });
    createAlink(url, '_blank');
  }

  function triggerGa4(fileName: string, opts?: OpenInStudioOpts) {
    const p = {
      file_name: fileName,
      content_id: detail.value.id,
      is_free: !isForSale.value,
      click_position: opts?.position,
      scene_name: isOpenFromStudioEditor() ? SceneName.EDITORS : SceneName.ATOMM,
      latest_page_name: ga4Params?.latest_page_name,
      latest_block_name: ga4Params?.latest_block_name,
      latest_keyword: ga4Params?.latest_keyword,
      latest_scene_name: String(ga4Params?.latest_scene_name || ''),
      latest_contentid_algorithm_version: ga4Params?.latest_contentid_algorithm_version,
      latest_contentid_algorithm_type: ga4Params?.latest_contentid_algorithm_type,
    };
    ga4OpenInStudioClick(p);
  }

  return {
    collapsedBtnRef,
    isCollapsed,
    onClickCollapse,
    goToLogin,
    isLogin,
    getPassportToken,
    callXcs,
    isCallingXcs,
    downloadFile,
    isDownloadLoading,
    isDownLoadAllLoading,
    p,
    currentFileUid,
    isForSale,
    isXthings,
    projectTitle,
    isFileAll,
    downloadBtnTitle,
    newAttachFiles,
    moreThanOneFile,
    displayFiles,
    xcsFileList,
    isOpenIconShow,
    isOpenBtnShow,
    hasButtonGroup,
    studioFileList,
    filesModalRef,
    isOpenInStudioBtnShow,
    onOpenInStudio,
    openAllInStudio,
    openOneInStudio,
    importToCanvasLoading,
    isInPcXcs,
    isImportToCanvasBtnShow,
    importOneToCanvas,
    onImportToCanvas,
    onDownloadClick,
    downloadAll,
    downloadOne,
    onOpenClick,
    openOne,
    openAll,
  };
};
