<template>
  <div class="sticky-box">
    <div ref="sideAnchorWrapper" class="side-anchor-wrapper">
      <div class="anchor-content">
        <div class="title text-16-semibold">{{ title || 'Contents' }}</div>

        <div class="anchor-list">
          <div ref="scrollbar" class="scrollbar-wrapper">
            <div class="scrollbar-track">
              <span
                v-show="showHighLightBlock"
                class="highlight-block"
                :style="{
                  top: hightLightTop + 'px',
                  height: hightLightHeight + 'px',
                }"
              ></span>
            </div>
          </div>

          <div class="list">
            <template v-for="anch in anchorList" :key="anch.id">
              <div v-if="anch.name" class="a-item" :class="[anch.children ? 'parent' : `a-item-${anch.id}`, anch.id === cur ? 'active' : '']">
                <template v-if="!anch.children">
                  <div class="two-line-ellipsis anch-name hover:text-text-primary" :title="anch.name" @click="(e: any) => handleAnchor(anch, e)">
                    {{ anch.name }}
                  </div>
                </template>

                <template v-else>
                  <div class="parent-name" :class="getIsParentActive(anch) ? 'active' : ''" @click="toggle(anch)">
                    <span class="two-line-ellipsis">{{ anch.name }}</span>
                    <icon-font class="icon" :class="toggleMap[anch.id] && 'icon-rotate'" type="icon-right-arrow-line" />
                  </div>
                  <div v-show="toggleMap[anch.id]" class="children">
                    <div
                      v-for="child in anch.children"
                      :key="child.id"
                      class="a-item child two-line-ellipsis"
                      :class="[cur === child.id ? 'active' : '', `child-${child.id}`, `a-item-${child.id}`]"
                      @click="(e: any) => handleAnchor(child, e)"
                    >
                      {{ translateName(child) }}
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
interface AnchorItem {
  name: string;
  id: string;
  desc?: string;
}
interface AnchorListItem extends AnchorItem {
  children?: AnchorItem[];
}
interface Props {
  anchorList: AnchorListItem[];
  title?: string;
  scrollId?: string;
}
type EmitsType = {
  (e: 'anchorClick', anchor: AnchorListItem): void;
};

const props = defineProps<Props>();
const emits = defineEmits<EmitsType>();

const toggleMap = ref<Record<string, boolean>>({});

const cur = ref(props.anchorList[0]?.id || '');
const scrollbar = ref();
const sideAnchorWrapper = ref<HTMLElement>();

// 初始化菜单
function initToggleMap() {
  props.anchorList.forEach((anch) => {
    if (anch.children) {
      toggleMap.value[anch.id] = true;
    }
  });
}

const hightLightTop = ref(0);
const hightLightHeight = ref(26);
const showHighLightBlock = ref(true);
let scrolling = false;
function setHighLightPos(eTarget: HTMLElement) {
  if (!scrollbar.value) {
    return;
  }
  nextTick(() => {
    const diff = (scrollbar.value as HTMLElement).offsetTop;

    hightLightHeight.value = eTarget.clientHeight;
    hightLightTop.value = eTarget.offsetTop - diff;
  });
}

function handleAnchor(anchor: AnchorListItem, e: Event) {
  emits('anchorClick', anchor);
  const { id } = anchor;
  showHighLightBlock.value = true;
  cur.value = id;
  setHighLightPos(e.target as HTMLElement);
  scrolling = true;
  const target = document.getElementById(id);
  if (target) {
    window.scrollTo({
      left: window.pageXOffset,
      top: window.pageYOffset + target.getBoundingClientRect().top - 128,
      behavior: 'smooth',
    });
  }

  setTimeout(() => {
    scrolling = false;
  }, 800);
}

const isCollapsed = ref(false);
function toggle(anch: AnchorListItem) {
  const show = (toggleMap.value[anch.id] = !toggleMap.value[anch.id]);
  isCollapsed.value = !show;

  if (show) {
    showHighLightBlock.value = true;
    const first: any = (anch.children as AnchorItem[])[0];

    handleAnchor(first, {
      target: document.querySelector('.child-' + first.id),
    } as Event);
  } else {
    const isCurChildrenActive = anch.children?.some((c) => c.id === cur.value);
    isCurChildrenActive && (showHighLightBlock.value = false);

    const target = document.querySelector('.a-item-' + cur.value);
    setHighLightPos(target as HTMLElement);
  }
}

function getIsParentActive(anchor: AnchorListItem) {
  return anchor.children?.some((c) => c.id === cur.value);
}
// onMounted(() => {
//   nextTick(initToggleMap);
// });

const ids: string[] = [];
let wrapperHeight = 0;
const handleUpdateHighLightPos = () => {
  if (scrolling) {
    return;
  }
  for (let i = 0, len = ids.length; i < len; i++) {
    const id = ids[i] as string;
    const target = document.getElementById(id);
    const top = target?.getBoundingClientRect().top as number;

    if (top <= 114) {
      showHighLightBlock.value = true;
      cur.value = id;

      const targetItem = document.querySelector(`.a-item-${id}`) as HTMLElement;
      if (targetItem) {
        const offsetTop = targetItem.getBoundingClientRect().top;
        const cursT = getWrapperScrollTop();
        if (offsetTop > cursT + wrapperHeight || offsetTop <= 0) {
          sideAnchorWrapper.value?.scroll({
            left: 0,
            top: offsetTop,
          });
        }
        setHighLightPos(targetItem);
      }
    }
  }
};

function getWrapperScrollTop() {
  if (sideAnchorWrapper.value) {
    return sideAnchorWrapper.value.scrollTop;
  }
  return 0;
}

function getWrapperHeight() {
  if (sideAnchorWrapper.value) {
    return sideAnchorWrapper.value.clientHeight;
  }
  return 0;
}
watch(
  () => props.anchorList,
  () => {
    listenTargetsPos();
    initToggleMap();
  },
);
function listenTargetsPos() {
  props.anchorList.forEach((anch) => {
    if (!anch.children) {
      ids.push(anch.id);
    } else {
      anch.children.forEach((c) => {
        ids.push(c.id);
      });
    }
  });

  if (!ids.length) {
    return;
  }

  window.addEventListener('scroll', handleUpdateHighLightPos);
}

function translateName(item: AnchorListItem) {
  if (item.desc) {
    return `${item.name}: ${item.desc}`;
  }

  return item.name;
}

onMounted(() => {
  setTimeout(() => {
    // resolve page transition
    listenTargetsPos();
  }, 500);

  nextTick(() => {
    wrapperHeight = getWrapperHeight();
  });
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleUpdateHighLightPos);
});
</script>

<style lang="less" scoped>
/* 滚动条整体 */
.anchor-list::-webkit-scrollbar {
  width: 4px;
}
/* 两个滚动条交接处 -- x轴和y轴 */
.anchor-list::-webkit-scrollbar-corner {
  background-color: transparent;
}
/* 滚动条滑块 */
.anchor-list::-webkit-scrollbar-thumb {
  border-radius: 22px;
  &:vertical {
    background-color: #eee;
  }
}
/* 滚动条轨道 */
.anchor-list::-webkit-scrollbar-track {
  background: transparent;
}
.side-anchor-wrapper {
  padding: 24px 0;
  background: #f8f8f8;
  // border-radius: 8px;
  // max-height: calc(100vh - 460px);
  overflow: auto;
  .anchor-content {
    .title {
      padding: 0 24px;
      margin-bottom: 24px;
    }
    .anchor-list {
      display: flex;
      padding: 0 24px;
      // height: 400px;
      max-height: calc(100vh - 460px);

      overflow-y: scroll;
      .scrollbar-wrapper {
        height: auto;
        .scrollbar-track {
          width: 2px;
          height: 100%;
          position: relative;
          background: white;

          .highlight-block {
            width: 2px;
            height: 30px;
            background-color: theme('colors.black.default');
            position: absolute;
            top: 20px;
            left: 0;
            transition: all ease 0.3s;
          }
        }
      }
      .list {
        margin-left: 8px;
        flex-grow: 1;
        .a-item {
          font-size: 18px;
          line-height: 26px;
          color: theme('colors.text.tertiary');
          margin-bottom: 24px;
          user-select: none;
          cursor: pointer;
          &.active {
            color: theme('colors.black.default');
            // font-weight: 600;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
          &.parent {
            // margin-bottom: 16px;
            &:last-of-type {
              margin-bottom: 0;
            }
            .parent-name {
              // margin-bottom: 16px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              user-select: none;
              cursor: pointer;
              &.active {
                color: #555;
              }
              .icon {
                font-size: 24px;
                // transform: rotate(90deg);
                transition: all 0.3s;
              }
              .icon-rotate {
                transform: rotate(90deg);
              }
            }
          }
          &.child {
            font-size: 16px;
            line-height: 24px;
            margin: 8px 0 0 8px;
            &:first-of-type {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
