<template>
  <div class="mt-8 mobile:pb-0">
    <a-checkbox v-model:checked="checked" @change="onChange">
      <span class="text-level-4 font-semibold text-text-primary">With images only</span>
    </a-checkbox>
  </div>
</template>

<script setup lang="ts">
import { useDetailsStore } from '@/stores/details';

type eventEmit = {
  (event: 'handleChange', value: boolean): void;
};
const emit = defineEmits<eventEmit>();

const detailsStore = useDetailsStore();
const { sensorCommentClick } = useSensors();

const detail = computed(() => detailsStore.detail);

const checked = ref(false);
const active = ref('all');
function onChange(e: any) {
  active.value = e.target.checked ? 'images' : 'all';
  emit('handleChange', active.value === 'all' ? false : true);

  sensorCommentClick({
    content_id: detail.value.id,
    content_name: detail.value.title,
    content_tag: detail.value.tags as string[],
    publish_time: detail.value.publishedAt,
    author_name: detail.value.createdByName,
    author_id: detail.value.createdBy,
    service_type: detail.value.type,
    elementId: active.value === 'all' ? 'All' : 'Contains images',
  });
}

function setActive(value: string) {
  checked.value = value === 'images';
  active.value = checked.value ? 'images' : 'all';
}

defineExpose({
  setActive,
});
</script>
