import { creditBuyApi, payOrderApi } from '@/api';
import { postEvent } from '@/api/activity';
import { getBaseDataApi, getBundleListByIds, getDiffucultyLevelData, getMaterialList } from '@/api/common';
import { DATA_TEMP_KEY, postDataTemp, WINDOW_PARAMS_KEY } from '@/api/data-temp';
import { getScenarioAssociation } from '@/api/share';
import { useDetailsStore } from '@/stores/details';
import { useLayoutStore } from '@/stores/layout';
import { useShareProject } from '@/stores/shareProject';
import { useXcsBridgeStore } from '@/stores/xcsBridge';
import { CONTENT_TYPE } from '@/types';
import { useCreditService } from '@atomm/atomm-pro';
import { message, Modal } from 'ant-design-vue';
import protocolCheck from 'custom-protocol-check';
import { defineStore, storeToRefs } from 'pinia';
import qs from 'qs';
import type { Ref } from 'vue';
import type { ItemType, MaterialInfosType } from '~/types';
import { COMMUNITY_BASE_DATA } from '~/types/constant';

const siteUrl = import.meta.env.VITE_PUBLIC_SITE_URL;
const passportUrl = import.meta.env.VITE_PASSPORT_SITE_URL;

export function initStates() {
  const { $sensors: sensors, $client } = useNuxtApp();
  const { sensorsDownloadXCS, sensorsBillingDetail, sensorsPaymentConfirm, sensorsCreditsDetail } = useSensors();
  const token = useMiddleCookie('utoken');

  const detailsStore = useDetailsStore();

  // 获取耗材列表
  const getBaseMaterialsData = async () => {
    const baseMaterials = ref<MaterialInfosType[]>([]);
    try {
      const { data } = await getMaterialList();
      baseMaterials.value = data?.value?.data || [];
    } catch (err) {
      console.log('getBaseMaterialsData-err');
    }
    return baseMaterials;
  };

  // 获取全部协议列表
  const getLicense = async () => {
    const licenseList = ref([]);
    try {
      const { data } = await getBaseDataApi(COMMUNITY_BASE_DATA.LICENSE);
      licenseList.value = data?.value?.data || [];
    } catch (err) {
      console.log('getLicense-err :>> ', err);
    }
    return licenseList;
  };

  // 获取全部设备列表
  const getBaseDevices = async () => {
    const baseDevices = ref([]);
    try {
      const { data } = await getBaseDataApi(COMMUNITY_BASE_DATA.DEVICE);
      baseDevices.value = data?.value?.data || [];
    } catch (err) {
      console.log('getBaseDevices-err :>> ', err);
    }
    return baseDevices;
  };

  // 获取设备信息
  const getDeviceInfo = async () => {
    const deviceInfoList = ref([]);
    try {
      const { data } = await getScenarioAssociation.fetch();
      deviceInfoList.value = data.machines ?? [];
    } catch (err) {
      console.log('getDeviceInfo-err :>> ', err);
    }
    return deviceInfoList;
  };

  /**
   * 初始化基础数据（用于setting字段的映射）
   */
  const initBaseData = async () => {
    const useShareProjectInfo = useShareProject();

    const { baseData } = storeToRefs(useShareProjectInfo);

    const getBaseProcessTypesData = async () => {
      const { data: baseProcessTypesData, error: baseProcessTypesError } = await getBaseDataApi(COMMUNITY_BASE_DATA.PROCESS);
      if (!baseProcessTypesError.value) {
        baseData.value.baseProcessTypes = baseProcessTypesData.value?.data;
      }
    };

    const getBaseBitmapsData = async () => {
      const { data: baseBitmapsData, error: baseBitmapsDataError } = await getBaseDataApi(COMMUNITY_BASE_DATA.BITMAP);
      if (!baseBitmapsDataError.value) {
        baseData.value.baseBitmaps = baseBitmapsData.value?.data;
      }
    };
    await Promise.all([getBaseProcessTypesData(), getBaseBitmapsData()]);
  };

  // 获取难度等级
  const getDifficultyLevel = async () => {
    const difficultyLevel = ref([]);
    try {
      const { data } = await getDiffucultyLevelData();
      difficultyLevel.value = data?.value?.data || [];
    } catch (err) {
      console.log('getDifficultyLevel-err :>> ', err);
    }
    return difficultyLevel;
  };

  // 获取bundle列表
  const getBundleList = async (params: { ids: string }) => {
    const bundleList = ref<any>([]);
    try {
      const res = await getBundleListByIds.fetch(params);
      bundleList.value = res.data ?? [];
    } catch (err) {
      console.log('getBundleList-err :>> ', err);
    }
    return bundleList;
  };

  // 获取神策参数
  const getSensorsParams = () => {
    const properties = sensors.getPresetProperties();
    const params = (window as any)?.df_shen?.getParams();
    const extra = {
      trafficSourceType: properties.$latest_traffic_source_type,
      searchKeyword: properties.$latest_search_keyword,
      utmSource: properties.$latest_utm_source,
      utmMedium: properties.$latest_utm_medium,
      utmCampaign: properties.$latest_utm_campaign,
      utmContent: properties.$latest_utm_content,
      utmTerm: properties.$latest_utm_term,
      latest_page_name: params.latest_page_name,
      latest_block_name: params.latest_block_name,
      latest_keyword: params.latest_keyword,
      latest_scene_name: String(params.latest_scene_name || ''),
      latest_contentid_algorithm_version: params.latest_contentid_algorithm_version,
      latest_contentid_algorithm_type: params.latest_contentid_algorithm_type,
    };
    return extra;
  };

  // 设置埋点数据
  const setSensorsTemp = async () => {
    const params = window?.df_shen?.getParams();
    const p = {
      key: DATA_TEMP_KEY,
      content: JSON.stringify(params),
    };
    try {
      postDataTemp.fetch(p);
    } catch (err) {
      console.log('setSensorsTemp-err :>> ', err);
    }
  };
  // 设置窗口参数
  const setWindowParams = async () => {
    const p = { key: WINDOW_PARAMS_KEY, content: isOpenFromStudioEditor() ? 'studio' : 'atomm' };
    try {
      postDataTemp.fetch(p);
    } catch (err) {
      console.log('setWindowParams-err :>> ', err);
    }
  };

  // 登录相关
  const { isLogin } = useLoginStatus();
  const passportToken = ref();
  function getPassportToken() {
    passportToken.value = $client.token;
    return passportToken;
  }
  function goToLogin() {
    $client.login();
  }

  // 唤醒xcs相关
  const layoutStore = useLayoutStore();
  function updateXcsDownloadCnt() {
    postEvent({ eventType: 'open_in_xcs' });
    detailsStore.triggerAction('download');
  }
  const handleShowDownloadXcs = () => {
    layoutStore.setIsShowDownloadXcs(true, 'detail');
  };
  const handleDownload = (url: string) => {
    const urlObj = new URL(url);
    // 下载类url需要给移动端传入标记进行拦截处理，download:
    // 点击普通下载，移动端可定义弹框提示让用户选择打开或者下载操作，open: 直接打开项目；
    urlObj.searchParams.append('type_to_app', 'open');
    window.open(urlObj.toString());
  };
  const isCallingXcs = ref(false);
  function callXcs(data: {
    type: any; // 类型
    token: string | null | undefined; // 令牌
    url: string; // 文件链接
    name: string; // 文件名
    makingId?: number; // 案例ID(打开多个文件时需要,最多支持打开6个)
  }) {
    isCallingXcs.value = true;
    // PC 端 xcs 软件内部 || studio editor 打开
    if (isElectronXcs() || isOpenFromStudioEditor()) {
      isCallingXcs.value = false;
      updateXcsDownloadCnt();
      window.parent.postMessage(data, '*');
      return;
    }

    // iPad浏览器中
    if (isIpad()) {
      isCallingXcs.value = false;
      sensorsDownloadXCS();
      handleShowDownloadXcs();
      return;
    }

    // 移动端webView内嵌，执行下载第一个文件的操作，由移动端拦截内容并执行处理逻辑
    if (isAppWebkit()) {
      isCallingXcs.value = false;
      sensorsDownloadXCS();
      handleDownload(data.url);
      return;
    }

    const parameter = btoa(encodeURIComponent(JSON.stringify(data)));
    const uri = `XCSApp://?data=${parameter}`;
    const failCb = () => {
      isCallingXcs.value = false;
      sensorsDownloadXCS();
      handleShowDownloadXcs();
    };
    const successCb = async () => {
      isCallingXcs.value = false;
      updateXcsDownloadCnt();
    };
    const timeOut = 3000;
    const unsupportedCb = () => {
      isCallingXcs.value = false;
      message.error('Application failed to open due to outdated version. Please update your browser or xTool Creative Space.');
    };
    protocolCheck(uri, failCb, successCb, timeOut, unsupportedCb);
  }

  // 处理订单相关
  const isBuyLoading = ref(false);
  async function handleBuyClick(
    detail: Ref<ItemType>,
    opts?: {
      position?: string;
      isShowModal?: boolean;
      isSubscription?: boolean;
      refreshDetail?: () => void;
    },
  ) {
    try {
      isBuyLoading.value = true;

      // 神策埋点
      if (opts?.position) {
        const params = (window as any)?.df_shen?.getParams();
        const p = {
          content_id: detail.value?.id as number,
          content_name: detail.value?.title as string,
          author_id: detail.value?.createdBy as number,
          author_name: detail.value?.createdByName as string,
          commodity_price: detail.value?.origPrice as number,
          discount_price: detail.value?.discount?.disPrice as number,
          click_positon: opts.position,
          latest_scene_name: String(params.latest_scene_name || ''),
          latest_page_name: params.latest_page_name,
          latest_block_name: params.latest_block_name,
          latest_keyword: params.latest_keyword,
          latest_contentid_algorithm_version: params.latest_contentid_algorithm_version,
          latest_contentid_algorithm_type: params.latest_contentid_algorithm_type,
        };
        sensorsBillingDetail(p);
      }

      // 检查是否断网
      if (!globalThis.navigator.onLine) {
        message.error('No internet connection detected. Please check your network settings and try again.');
        return;
      }

      // 检查是否登录
      if (!isLogin.value) {
        goToLogin();
        return;
      }

      // 如果是 xThings，走 xThings 购买流程
      const isXthings = computed(() => detail.value?.type === CONTENT_TYPE.XTHINGS);
      if (isXthings.value) {
        goToXthingsPage(detail.value?.productInfo?.purchaseLink as string);
        return;
      }

      // 储存神策参数
      setSensorsTemp();

      const { successUrl, cancelUrl } = getPayLinkUrl(detail.value.id, token.value as string);
      const params = {
        makingId: detail.value.id,
        successUrl,
        cancelUrl,
        extra: getSensorsParams(),
      };

      // 创建订单，并得到支付链接
      const { data } = await payOrderApi(params);
      if (data?.value?.code === 0) {
        if (data.value?.data) {
          const { payUrl } = data.value.data;

          // electron内嵌iframe，新开窗口到浏览器打开，需要在当前页显示弹窗
          const isInStudioEditor = isOpenFromStudioEditor();
          if (isElectron() || isInWujie() || isInStudioEditor) {
            isInStudioEditor && setWindowParams();
            createAlink(payUrl, '_blank', true);
            opts?.isShowModal && showConfirm(detail, opts?.refreshDetail);
            return;
          }

          // PC web端 或 手机/iPad端(移动端无新开窗口)，当前页打开
          if (!isElectron() || isIpad() || isAppWebkit()) {
            createAlink(payUrl);
          }
        }
      } else {
        // 若生成订单失败，刷新状态(防止已支付的案例未更新)
        // getProjectDetail();
        opts?.refreshDetail?.();
      }
    } catch (err) {
      console.error('buy-click-error :>> ', err);
    } finally {
      isBuyLoading.value = false;
    }
  }
  // 打开确认弹窗，确认是否支付成功，支付成功后刷新详情页
  const payConfirmModal = ref();
  function showConfirm(detail: Ref<ItemType>, cb?: () => void) {
    payConfirmModal.value = Modal.confirm({
      title: 'Have you completed the payment?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No, I want to retry',
      transitionName: '',
      onOk() {
        sensorsPaymentConfirm({ type: 'yes' });
        // getProjectDetail();
        cb && cb();
      },
      onCancel: () => {
        sensorsPaymentConfirm({ type: 'no' });
        handleBuyClick(detail, {
          isShowModal: false,
          isSubscription: false,
          refreshDetail: cb,
        });
        return Promise.reject(false);
      },
    });
  }

  const creditService = useCreditService();
  const creditBuyService = useCreditBuy();
  async function handleCreditBuy(position: string) {
    // 检查是否断网
    if (!globalThis.navigator.onLine) {
      message.error('No internet connection detected. Please check your network settings and try again.');
      return;
    }

    const detail = detailsStore.detail;
    sensorsCreditsDetail({
      content_id: detail.id,
      content_name: detail.title,
      discount_price: detail.credits,
      click_positon: position,
    });

    // 检查是否登录
    if (!isLogin.value) {
      goToLogin();
      return;
    }

    // 检查积分是否足够
    if (detailsStore.detail.credits > creditService.myCreditCnt) {
      creditService.openPurchaseModal({
        msg: 'Insufficient credits',
        trace: {
          content_id: detail.id,
          content_name: detail.title,
          source: `detail ${position}`,
        },
      });
    } else {
      creditBuyService.openCreditBuyModal();
    }
  }

  return {
    getBaseMaterialsData,
    getLicense,
    getBaseDevices,
    getDifficultyLevel,
    getBundleList,
    getDeviceInfo,
    initBaseData,
    getSensorsParams,
    isLogin,
    getPassportToken,
    goToLogin,
    callXcs,
    isCallingXcs,
    isBuyLoading,
    handleBuyClick,
    handleCreditBuy,
    payConfirmModal,
  };
}

/**
 * 用于处理下载文件的逻辑
 */
export function useFileMethods() {
  const xcsBridgeStore = useXcsBridgeStore();

  const downloadFile = async (
    file: {
      url: string;
      name: string;
      [key: string]: any;
    },
    cb?: () => void, // 下载成功后的回调
    failCb?: () => void, // 下载失败后的回调
    progressFn?: (percent: number) => void, // 下载进度
  ) => {
    // console.log('file :>> ', file);
    const AppApiInfo = await xcsBridgeStore.getAppApi();
    const isApp = AppApiInfo.openNative.isApp();
    if (isApp) {
      // 在pad的xcs中的下载逻辑
      try {
        const appVersion = await AppApiInfo.app.getAppVersion();
        const appVersionInt = parseInt(appVersion.replace(/\./g, ''));
        if (appVersionInt < 200) {
          // 2.0.0版本以下的处理逻辑
          fn(file, cb, failCb, progressFn);
        } else {
          // 2.0.0版本及以上的处理逻辑 code 0 表示成功
          status.value = 'start';
          updateProgress(progressFn, 100);
          const { code } = await AppApiInfo.openNative.downloadAndShareFile(file.url, file.name);
          status.value = 'done'; // 按下载完成处理
          if (code !== 0) {
            failCb && failCb();
          } else {
            cb && cb();
          }
        }
      } catch (err) {
        console.log('downloadFile-err :>> ', err);
        failCb && failCb();
      }
    } else {
      fn(file, cb, failCb, progressFn);
    }
  };

  const status = ref('start');
  function updateProgress(cb?: (percent: number) => void, time?: number) {
    let percent = 0;
    const interval = setInterval(() => {
      if (status.value === 'start') {
        if (percent < 99) {
          percent += 1;
          cb && cb(percent); // 更新进度
        } else {
          clearInterval(interval); // 清除定时器
        }
      }
      if (status.value === 'done') {
        cb && cb(100); // 请求完成，设置进度为 100%
        clearInterval(interval);
      }
    }, time || 100); // 默认每 100 毫秒更新一次
  }

  /**
   * 整合后的下载逻辑, AImake 图片直接下载，其他图片需要进行校验
   */
  function fn(file: any, cb?: () => void, failCb?: () => void, progressFn?: (percent: number) => void) {
    if (file?.extra?.source === 'AImake') {
      downloadWithAsync(file, cb, failCb, progressFn);
    } else {
      downloadWithUrl(file, cb, failCb, progressFn);
    }
  }

  // 校验下载链接是否有效
  function downloadWithUrl(file: any, cb?: () => void, failCb?: () => void, progressFn?: (percent: number) => void) {
    const urlObj = new URL(file.url);
    // 下载类url需要给移动端传入标记进行拦截处理，download: 点击普通下载，移动端可定义弹框提示让用户选择打开或者下载操作，open: 直接打开项目；
    urlObj.searchParams.append('type_to_app', 'download');
    const urlString = urlObj.toString();
    const isValid = isValidURL(urlString);
    if (!isValid) {
      message.warning(`Download link expired. Please revisit template details and click 'Download' for a new link.`);
      failCb && failCb();
      return;
    }
    downloadWithAsync(file, cb, failCb, progressFn);
  }

  // 异步下载
  async function downloadWithAsync(file: any, cb?: () => void, failCb?: () => void, progressFn?: (percent: number) => void) {
    try {
      const blob = await getBlob(file.url, (percent: number) => {
        progressFn && progressFn(+percent.toFixed(2));
      });
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob as Blob);
      a.setAttribute('href', objectUrl);
      a.setAttribute('download', file.name || 'image.png');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(objectUrl);
      a.remove();
      cb && cb();
    } catch (err) {
      failCb && failCb();
    }
  }

  return {
    downloadFile,
  };
}

export const useCreditBuy = defineStore('creditBuy', () => {
  const showModal = ref(false);
  const detailService = useDetailsStore();

  const creditService = useCreditService();

  const openCreditBuyModal = () => {
    showModal.value = true;
  };
  const closeCreditBuyModal = () => {
    showModal.value = false;
  };

  const loading = ref(false);
  const buy = async () => {
    try {
      loading.value = true;
      const res = await creditBuyApi.fetch({
        makingId: detailService.detail.id,
      });
      if (res.code !== RespCode.SUCCESS) {
        throw new Error(res.message || 'Credit buy failed');
      }
      await Promise.all([creditService.refreshCreditCnt(), detailService.refresh()]);
      closeCreditBuyModal();
      message.success({
        content: () =>
          h('span', null, {
            default: () => [
              h('span', null, `Redeemed with ${detailService.detail.credits} credits successfully. Remaining credits: `),
              h('span', { style: { color: '#FF7C23' } }, creditService.myCreditCnt + ''),
            ],
          }),
      });
    } finally {
      loading.value = false;
    }
  };
  return {
    showModal,
    openCreditBuyModal,
    closeCreditBuyModal,
    loading,
    buy,
  };
});

/**
 * 提前检测一个文件下载地址是否具备可访问性
 * @param url 文件的下载地址
 * @returns 是否具备可访问性
 */
export function isValidURL(url: string) {
  if (!url) {
    return false;
  }

  try {
    const arr = url.split('?') as any;
    const { expire } = qs.parse(arr[1]);
    if (!expire) {
      return true;
    }
    const now = +new Date() / 1000;
    // 如果有多个过期时间，取最后一个
    if (Array.isArray(expire) && expire.length >= 1) {
      const suffix = expire.pop() as string;
      return now < +suffix;
    }
    return now < +expire;
  } catch (err) {
    // 链接解析有误, 默认为有效, 避免点击下载无反应
    console.log('isValidURL-err :>> ', err);
    return true;
  }
}

/**
 * 创建a标签，用于打开链接，可以选择打开方式
 */
export function createAlink(url: string, target: string = '_self', keepOpener: boolean = false) {
  if (target === '_blank' && keepOpener) {
    window.open(url, '_blank');
  } else {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.setAttribute('href', url);
    a.setAttribute('target', target);
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
}

export function goToXthingsPage(url: string) {
  // 移动端app中，当前页打开，其他情况新开窗口
  if (isAppWebkit()) {
    createAlink(url);
  } else {
    createAlink(url, '_blank');
  }
}

// 获取支付链接
const getPayLinkUrl = (id: number, token: string) => {
  return {
    successUrl: `${passportUrl}/en/authcb?ut=${token}&r=${siteUrl}/pay/status`,
    cancelUrl: globalThis.location.href,
  };
};
