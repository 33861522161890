<template>
  <div class="flex min-h-[40px] items-center gap-2 rounded-lg border border-solid border-background-4 bg-background-2 px-2 mobile:px-2">
    <icon-font type="icon-Setting" class="text-[20px] mobile:text-[16px]" />
    <span class="text-level-4 font-semibold text-text-primary mobile:text-level-4">Setting {{ index + 1 }}</span>
    <div class="relative flex flex-1">
      <span class="absolute left-[0px] top-1/2 h-[calc(100%-10px)] -translate-y-1/2 border-0 border-r-[1px] border-solid border-background-4"></span>
      <icon-font v-if="showIcon" type="icon-edit-box" class="ml-2 text-[20px] mobile:text-[16px]" />
      <df-textarea
        v-model:value="value"
        class="flex"
        :placeholder="isMobile ? 'Please enter ' : 'Machining Mode | Machining Method (Special）'"
        auto-size
        :disabled="isPreview"
        :maxlength="700"
      />
    </div>
    <icon-font
      v-if="!isPreview"
      type="icon-Delete"
      class="cursor-pointer text-[24px] hover:text-status-warning mobile:text-[16px]"
      @click="deleteItem"
    />
  </div>
</template>
<script setup lang="ts">
import { useShareProject } from '@/stores/shareProject';
import { capFirst } from '@/utils/fns/convert.js';
const useShareProjectInfo = useShareProject();
const baseProcessTypes = computed(() => useShareProjectInfo.baseData.baseProcessTypes) as any;
const baseBitmaps = computed(() => useShareProjectInfo.baseData.baseBitmaps) as any;
const value = defineModel('value', { type: String, default: '' });
const { isMobile } = useScreen();
type PropType = {
  index: number;
  setting: any;
  isPreview?: boolean;
  showIcon?: boolean;
};
const props = withDefaults(defineProps<PropType>(), {
  isPreview: false,
  showIcon: true,
});
type emitsType = {
  (event: 'emitDelete', value: any): void;
};
const emit = defineEmits<emitsType>();
const deleteItem = () => {
  emit('emitDelete', props.index);
};
const excludeKey = ['uid', 'type', 'custom', 'processType', 'bitmapMode']; // 不用拼接的字段
const newKey = ['type', 'uid', 'custom']; // 新数据有值的字段
watch(
  () => [props.setting, baseProcessTypes.value, baseBitmaps.value],
  () => {
    const newValue = props.setting;
    let isOldData = false;
    // 兼容历史数据 ：将旧表单转成自定义字符串存在custom字段里
    if (newValue.custom !== '') {
      isOldData = false;
    } else {
      isOldData = Object.keys(newValue).some((key) => {
        return !(newKey.includes(key) || newValue[key] === null || newValue[key] === undefined || newValue[key] === '');
      });
    }
    if (isOldData) {
      let test = '';
      if (newValue.processType) {
        const text = baseProcessTypes.value.find((item: any) => item.id === newValue.processType)?.name;
        test = text ? `${text} | ` : '';
      }
      if (newValue.bitmapMode) {
        const text = baseBitmaps.value.find((item: any) => item.id === newValue.bitmapMode)?.name;
        test += text ? `${text} | ` : '';
      }
      for (let i in newValue) {
        if (!excludeKey.includes(i) && newValue[i]) {
          let res = newValue[i];
          test += `${capFirst(i)}: ${res} | `;
        }
      }
      value.value = test.slice(0, -2);
    }
  },
  { immediate: true },
);
</script>
<style lang="less" scoped>
:deep(.ant-input) {
  border: 0;
  box-shadow: none;
  //position: relative;
  //top: 2px;
  background: transparent !important;
  padding-left: 0;
  font-size: 14px;
  &.ant-input-disabled,
  &.ant-input[disabled] {
    cursor: auto;
    color: theme('colors.text.tertiary');
  }
}
</style>
