<template>
  <div class="comment-user-info flex gap-2">
    <div
      class="uname cursor-pointer text-level-4 font-semibold text-text-primary mobile:text-level-4"
      :title="answer.createdByName"
      @click="handleClick"
    >
      {{ answer.createdByName }}
    </div>
    <span
      v-if="isAuthor"
      title="Author"
      :class="'inline-flex h-5 rounded-sm bg-[rgba(255,124,35,0.10)] px-2 text-[12px] leading-5 text-status-alert mobile:text-[12px]'"
    >
      Author
    </span>
    <a-tooltip :title="timeTitle">
      <span class="comment-date text-level-4 text-text-tertiary mobile:whitespace-nowrap mobile:text-[12px]">{{ timeText }}</span>
    </a-tooltip>
  </div>
</template>

<script lang="ts" setup>
import { useUserInfo } from '@/stores/user';
import type { ItemType } from '@/types/common';
import moment from 'moment';

type PropsType = {
  objectDetail: ItemType;
  answer: any;
};
const props = defineProps<PropsType>();

const useUserInfoStore = useUserInfo();

const isAuthor = computed(() => props.objectDetail?.createdBy === props.answer.createdBy);
const time = computed(() => props.answer.createdAt * 1000);
const timeTitle = computed(() => moment(time.value).format('YYYY-MM-DD HH:mm'));
const timeText = computed(() => moment(time.value).startOf('minute').fromNow());

const handleClick = () => {
  if (props.answer.createdBy === useUserInfoStore.myInfo.id) {
    if (isOpenFromStudioEditor()) {
      createAlink(`/user-center?active=projects`, '_blank');
    } else {
      navigateTo({ path: '/user-center', query: { active: 'projects' } });
    }
  } else {
    if (isOpenFromStudioEditor()) {
      createAlink(`/profile/${props.answer.createdBy}`, '_blank');
    } else {
      navigateTo(`/profile/${props.answer.createdBy}`);
    }
  }
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 425px) {
  .uname {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
