<template>
  <div class="step-item-wrapper mb-8">
    <div class="step-title flex items-center gap-4 pad:gap-2 mobile:gap-2">
      <div
        :class="'flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-black-default text-[16px] font-bold text-[#fff] pad:h-6 pad:w-6 pad:text-[14px] mobile:h-6 mobile:w-6 mobile:text-[14px]'"
      >
        {{ index }}
      </div>
      <div class="one-line-ellipsis text-[18px] font-semibold leading-6 text-text-primary pad:text-level-4 mobile:text-level-4">
        {{ `Step${index}` + (step.title ? `: ${step.title}` : '') }}
      </div>
    </div>

    <div class="step-body pl-3 pad:pl-3 mobile:pl-3">
      <div class="mt-4 border-0 border-l border-dashed border-[rgba(0,0,0,0.1)] pl-7 pad:pl-5 mobile:pl-5">
        <!-- 视频 -->
        <div v-for="video in videoList" :key="video.uid" class="mb-4 overflow-hidden rounded-lg">
          <RatioBox>
            <div v-if="VideoTypes.includes(video.type)" class="flex h-full w-full items-center bg-uni-0">
              <video
                class="block h-full w-full object-contain"
                :poster="video.thumbnailUrl"
                controls
                disablepictureinpicture
                muted
                playsinline
                controlslist="nodownload"
                preload="metadata"
                @contextmenu.prevent
              >
                <source :src="video.url" type="video/mp4" />
                <source :src="video.url" type="video/mov" />
                <source :src="video.url" type="video/webm" />
                <p>Your browser does not support the video tag.</p>
              </video>
            </div>
            <iframe
              v-else
              class="video-iframe h-full w-full"
              allowfullscreen
              referrerpolicy="no-referrer"
              :src="video.videoUrl"
              frameborder="0"
            ></iframe>
          </RatioBox>
        </div>

        <!-- 图片 -->
        <PhotoList v-if="step.images.length > 0" class="mb-4 overflow-hidden rounded-lg" :images="step.images" />

        <!-- 普通案例 settings -->
        <div v-if="step.paraSettings.length > 0 && !isXthings" class="mb-4">
          <div class="mt-4 flex flex-col gap-2">
            <template v-for="(settingItem, pIndex) in step.paraSettings" :key="`${pIndex}${settingItem.uid}`">
              <div v-if="settingItem.type === 'custom' && settingItem.custom">
                <ClientOnly>
                  <TextSetting v-model:value="settingItem.custom" :setting="settingItem" :index="pIndex" is-preview :show-icon="false" />
                </ClientOnly>
              </div>
              <div v-if="settingItem.type == 'xcs'">
                <ClientOnly>
                  <XcsSetting :setting="settingItem" :index="pIndex" is-preview />
                </ClientOnly>
              </div>
            </template>
          </div>
        </div>
        <!-- xthings settings -->
        <div v-if="isXthings && step.groupParaSettings.length > 0" class="mb-4">
          <div v-for="(paraSettings, pIndex) in step.groupParaSettings" :key="pIndex" class="setting-item">
            <XthingsSetting :para-settings="paraSettings" />
          </div>
        </div>

        <!-- 富文本内容 -->
        <div class="ql-container ql-snow html-content">
          <editor-preview class="rich-text-renderer text-[16px]" :preview-content="step.content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TextSetting from '@/components/share/project/instruction/textSetting.vue';
import XcsSetting from '@/components/share/project/instruction/xcsSetting.vue';
import { CONTENT_TYPE, type StepsItem } from '@/types';
import XthingsSetting from './XthingsSetting.vue';

type PropsType = {
  item: StepsItem;
  index?: number;
  type?: CONTENT_TYPE;
};

const props = defineProps<PropsType>();

const VideoTypes = ['mp4', 'mov', 'webm'];

const step = computed(() => {
  return props.item;
});
const videoList = computed(() => {
  const videos = step.value.videos ?? [];
  const list = videos.map((v) => {
    const suffix = v.name?.split('.')?.pop()?.toLowerCase();
    if (VideoTypes.includes(suffix)) {
      return {
        ...v,
        type: suffix,
        url: v.videoUrl,
        thumbnailUrl: getOssProcessedImage(v.thumbnailUrl, '', 'webp'),
      };
    }
    return {
      ...v,
      type: 'video',
      url: v.videoUrl,
      thumbnailUrl: getOssProcessedImage(v.thumbnailUrl, 'm_fill,w_800,h_600', 'webp'),
    };
  });
  return list;
});
const isXthings = computed(() => props.type === CONTENT_TYPE.XTHINGS);
</script>

<style lang="less" scoped>
.rich-text-renderer {
  font-family: 'Inter', 'Segoe UI', Roboto, Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: theme('colors.text.secondary');
}
</style>
