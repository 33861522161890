<template>
  <div
    id="sticky-tab-bar"
    class="sticky-tab-bar sticky top-[63px] z-50 w-full bg-[rgba(255,255,255,0.92)] backdrop-blur-lg"
    :class="[
      {
        '!static': isPreview || audit,
        '!top-0': isInWujie() || isOpenFromStudioEditor(),
      },
    ]"
  >
    <a-tabs v-model:activeKey="activeKey" class="w-full" :tab-position="'top'" @change="onTabChange" @tab-click="onTabClick">
      <a-tab-pane v-for="tab in displayTabs" :key="tab.key" :disabled="tab.key === 'Comments' && (isPreview || audit)">
        <template #tab>
          <div
            class="mb-0 text-[18px] font-semibold leading-[24px] hover:text-text-primary mobile:text-level-4"
            :class="[tab.key === activeKey ? 'text-text-primary' : 'text-text-tertiary']"
          >
            <span>{{ tab.name }}</span>
            <span v-if="tab.key === 'Comments' && comment > 0">{{ ` (${comment})` }}</span>
          </div>
        </template>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script lang="ts" setup>
import type { DetailProvide } from '@/components/project-details/types';
import { useDetailsStore } from '@/stores/details';

type PropsType = {
  forSale?: boolean;
  hasInstruction?: boolean;
  hasRight?: boolean;
};
const props = withDefaults(defineProps<PropsType>(), {
  forSale: false,
  hasRight: false,
});
type EmitsType = {
  (event: 'change', key: string): void;
  (event: 'click', key: string): void;
};
const emits = defineEmits<EmitsType>();

const { isPreview, audit } = inject('detail-common') as DetailProvide;

const detailsStore = useDetailsStore();
const { sensorContentDetailTabClick } = useSensors();

const comment = computed(() => detailsStore.commentCount);

const tabs = [
  { key: 'Information', name: 'Information' },
  { key: 'Instruction', name: 'Instruction' },
  { key: 'Comments', name: 'Comments' },
];
const displayTabs = computed(() => {
  if (props.hasInstruction && props.hasRight) {
    return tabs;
  }
  return tabs.filter((item) => item.key !== 'Instruction');
});
const activeKey = ref(tabs[0]?.key);
const onTabChange = (activeKey: any) => {
  emits('change', activeKey);
};
const onTabClick = (key: any) => {
  sensorContentDetailTabClick({ element_name: key });
  emits('click', key);
};
const setActive = (key: string) => {
  activeKey.value = key;
};

defineExpose({
  setActive,
});
</script>

<style lang="less" scoped>
:deep(.ant-tabs) {
  .ant-tabs-nav {
    margin-bottom: 0;
    .ant-tabs-nav-operations {
      display: none;
    }
    .ant-tabs-nav-wrap {
      justify-content: center;
      .ant-tabs-nav-list {
        padding-top: 14px;
      }
    }
  }

  .ant-tabs-tab {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;
    margin-left: 32px;
    .anticon {
      margin-right: 0;
    }
    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 4px;
      background-color: transparent;
      border-radius: 2px;
      transition-property: background-color;
      transition-duration: 0.2s;
    }
  }
  .ant-tabs-tab-active {
    &::after {
      background-color: #ff0035;
    }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
}

// 移动端
@media screen and (max-width: 768px) {
  :deep(.ant-tabs) {
    .ant-tabs-tab {
      gap: 8px;
      margin-left: 0;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 28px;
    }
  }
}
</style>
