<template>
  <div class="relative">
    <div class="flex min-h-[40px] items-center gap-2 rounded-lg border border-solid border-background-4 bg-background-2 px-2 py-1 mobile:px-2">
      <icon-font type="icon-Setting" class="text-[20px] mobile:text-[16px]" />
      <span class="text-level-4 font-semibold text-text-primary mobile:text-level-4">Setting {{ index + 1 }}</span>
      <span class="h-[26px] border-0 border-r-[1px] border-solid border-background-4"></span>
      <icon-font type="icon-Laser" class="text-[20px] mobile:text-[16px]" />
      <span class="line-clamp-1 flex-1 break-all text-text-tertiary">
        <!-- 光线类型- 加工模式- 加工类型 -->
        {{ setting.laserName ? setting.laserName + '-' : '' }} {{ setting.modeName }} - {{ setting.processingTypeName }} -
        <span v-for="(val, key, i) of setting.parameter" :key="key" :class="[i < Object.keys(setting.parameter).length - 1 ? 'line-after' : '']">
          {{ capFirst(String(key)) }}: {{ val }}&nbsp;
        </span>
      </span>
      <icon-font type="icon-eye-line" class="mx-1 cursor-pointer text-[20px] mobile:hidden" @click="previewCanvas" />
      <icon-font
        v-if="!isPreview"
        type="icon-Delete"
        class="cursor-pointer text-[20px] hover:text-status-warning mobile:text-[16px]"
        @click="deleteItem"
      />
    </div>
    <div class="absolute right-0 top-14 z-[1000] w-[640px]">
      <prev-setting v-model:visible="visible" :setting="setting" :file="setting.file" :title="'Setting' + (index + 1)" />
    </div>
  </div>
</template>
<script setup lang="ts">
import prevSetting from '@/components/canvas/prevSetting.vue';
type Props = {
  setting: any;
  index: number;
  isPreview?: boolean;
};
type emitsType = {
  (event: 'emitDelete', value: any): void;
};
const props = defineProps<Props>();
const emit = defineEmits<emitsType>();
const visible = ref(false);
const previewCanvas = () => {
  visible.value = true;
};
const deleteItem = () => {
  emit('emitDelete', props.index);
};
</script>
<style lang="less" scoped>
:deep(.ant-input) {
  border: 0;
  box-shadow: none;
  position: relative;
  top: 2px;
  background: transparent;
  padding-left: 0;
}
</style>
