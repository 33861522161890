<template>
  <div v-if="isBundle && bundleInfos.length > 0" class="bundle-list mb-8">
    <h3 class="mb-0 text-level-2 font-semibold text-text-primary pad:text-level-2 mobile:text-level-2">This bundle includes</h3>

    <div class="makings flex flex-wrap overflow-hidden">
      <BundleCard v-for="item in bundleInfos" :key="item.id" class="bundle-item" :has-right="hasRight" :bundle-item="item" />
    </div>

    <div v-if="!hasRight" class="flex items-center justify-end gap-5 border-0 border-b border-solid border-[rgba(0,0,0,0.1)] pb-4">
      <span class="text-level-4 text-text-primary">Bundle Price</span>
      <div class="inline-flex h-[42px] overflow-hidden rounded-lg mobile:h-[36px]">
        <div v-if="discountPercent" class="flex items-center bg-[#ff7c23] px-[14px] text-[20px] font-bold text-[#fff] mobile:text-[14px]">
          {{ `-${discountPercent}%` }}
        </div>
        <div class="price flex items-center gap-1 bg-uni-0 px-4">
          <span class="text-[20px] font-bold text-[#fff] mobile:text-[14px]">
            {{ isDiscount ? priceInfo.disCountPrice : priceInfo.origPrice }}
          </span>
          <s v-if="isDiscount" class="text-level-2 text-[#fff] opacity-40 mobile:text-level-7">{{ priceInfo.origPrice }}</s>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { priceComputed } from '@/components/project-details/common/price';
import { useDetailsStore } from '@/stores/details';
import { CONTENT_TYPE } from '~/types';
import BundleCard from './BundleCard.vue';

const detailsStore = useDetailsStore();

const detail = computed(() => detailsStore.detail);
const bundleInfos = computed(() => detail.value.bundleInfos ?? []);
const hasRight = computed(() => detail.value.hasRight);
const isBundle = computed(() => detail.value.type === CONTENT_TYPE.BUNDLE);
const priceInfo = computed(() => priceComputed(detail.value));
const isDiscount = computed(() => detail.value.hasDiscount);
const discountPercent = computed(() => detail.value.discount?.discountPercent);
</script>

<style lang="less" scoped>
.makings {
  --gap: 16px;
  padding-bottom: 24px;
  margin-left: calc(-1 * var(--gap));
  @media (max-width: 767px) {
    margin-left: 0;
    margin-bottom: 24px;
  }
  .bundle-item {
    @media (max-width: 767px) {
      --c: 2;
    }
    @media (min-width: 768px) {
      --c: 2;
    }
    @media (min-width: 992px) {
      --c: 3;
    }
    @media (min-width: 1200px) {
      --c: 3;
    }
    @media (min-width: 1600px) {
      --c: 3;
    }
    width: calc(100% / var(--c) - var(--gap));
    margin-top: var(--gap);
    margin-left: var(--gap);
    @media (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}
.price {
  backdrop-filter: blur(7.5px);
}
</style>
