<template>
  <div v-if="tagInfos.length > 0" id="detail_wrapper" class="mb-8">
    <h3 class="mb-0 text-level-2 font-semibold text-text-primary pad:text-level-2 mobile:text-level-2">Tags</h3>
    <div class="mt-4 flex flex-wrap gap-2">
      <div v-for="(tag, index) in tagInfos" :key="index" class="inline-block">
        <NuxtLink :to="tag.tagJump" target="_blank">
          <TagItem :tag="tag.tagName" :tag-type="tag.tagType" />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TagItem from '@/components/project-details/common/TagItem.vue';
import { useDetailsStore } from '@/stores/details';

const detailsStore = useDetailsStore();

const detail = computed(() => detailsStore.detail);
const tagInfos = computed(() => {
  const list = detail.value.tagInfos ?? [];
  return list.map((item) => {
    if (item.tagType === 'search') {
      return {
        ...item,
        tagJump: `search-result?q=${item.tagName}&field=tag`,
      };
    }
    return item;
  });
});
</script>

<style lang="less" scoped></style>
