<template>
  <div
    v-if="!isPreview && (showEdit || showDel)"
    :class="'fixed bottom-[132px] right-[12px] z-50 hidden w-[44px] flex-col gap-4 pad:bottom-[168px] pad:right-[20px] pad:flex mobile:bottom-[132px] mobile:right-[12px] mobile:flex'"
  >
    <div
      v-if="showEdit"
      class="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-[#fff] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.1)]"
      @click="onEdit"
    >
      <NuxtIcon name="df:icon-edit-box" class="text-[20px]" />
    </div>
    <div
      v-if="showDel"
      class="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-[#fff] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.1)]"
      @click="handleDeleteClick"
    >
      <NuxtIcon name="df:icon-Delete" class="text-[20px]" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useActions } from '@/components/project-details/common/action';
import type { AuditStatus } from '@/components/project-details/types';

type PropsType = {
  auditStatus?: AuditStatus;
};
const props = withDefaults(defineProps<PropsType>(), {
  auditStatus: undefined,
});

const { editable, showEdit, showDel, handleEditClick, handleDeleteClick, isPreview } = useActions({
  auditStatus: props.auditStatus,
});

const onEdit = () => {
  if (!editable.value) {
    message.info('Your content has a version under review and cannot be edited.');
    return;
  }
  handleEditClick();
};
</script>

<style lang="less" scoped></style>
