const STUDIO_PROJECT_SITE = import.meta.env.VITE_STUDIO_PROJECT_SITE;

export const useAiGenerate = () => {
  function handleTryAi(data: any) {
    const { $client } = useNuxtApp();
    const { isLogin } = useLoginStatus();

    if (!isLogin.value) {
      $client.login();
      return;
    }

    const url = `${STUDIO_PROJECT_SITE}??aiMakeImageType=default&aiMakeStyle=${data.style}&aiMakeScene=${data.scene}&aiMakeParams=${btoa(JSON.stringify(data))}`;
    createAlink(url, '_blank');
  }

  return {
    handleTryAi,
  };
};
