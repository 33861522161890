<template>
  <div class="price-entry mt-6 flex flex-wrap items-center justify-between gap-2 border-0 border-t border-solid border-[#000]/10 pt-6">
    <div class="money-display">
      <span :class="'inline-flex h-8 text-[24px] font-bold leading-8 text-text-primary pad:text-[24px] mobile:text-[24px]'">
        {{ isDiscount ? priceInfo.disCountPrice : priceInfo.origPrice }}
      </span>
      <template v-if="isDiscount">
        <span :class="'ml-2 text-level-4 text-text-tertiary line-through pad:text-[14px] mobile:text-[14px]'">
          {{ priceInfo.origPrice }}
        </span>
        <a-tooltip>
          <template #title>
            <div class="whitespace-normal">{{ priceInfo.tips }}</div>
          </template>
          <div :class="'ml-2 inline-flex rounded bg-[#FF7C23]/[.1] px-2 text-level-4 text-[#FF7C23] pad:text-[14px] mobile:text-[14px]'">
            {{ `${priceInfo.disPercent}% off` }}
          </div>
        </a-tooltip>
      </template>
    </div>
    <div v-if="!(isPreview || isOpenFromStudioEditor())" class="flex cursor-pointer items-center gap-1" @click="handleChangePrice">
      <span class="whitespace-nowrap text-level-7 text-text-primary pad:text-[12px] mobile:text-[12px]">Change price</span>
      <icon-font class="text-[14px]" type="icon-a-30-arrow-right-s-line" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { priceComputed } from '@/components/project-details/common/price';
import type { DetailProvide } from '@/components/project-details/types';
import { useDetailsStore } from '@/stores/details';

const { isPreview } = inject('detail-common') as DetailProvide;

const detailsStore = useDetailsStore();
const router = useRouter();

const detail = computed(() => detailsStore.detail);
const isDiscount = computed(() => detail.value.hasDiscount);
const priceInfo = computed(() => priceComputed(detail.value));

function handleChangePrice() {
  router.push('/creator/center/store');
}
</script>

<style lang="less" scoped></style>
