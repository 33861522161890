<template>
  <df-btn :shape="shape" :size="size" class="action-button" :disabled="disabled" :class="active ? 'done' : ''">
    <div class="content flex items-center justify-center gap-1">
      <icon-font v-if="icon && iconType === 'icon-font'" class="icon !text-[24px]" :type="iconDisplay" />
      <NuxtIcon v-if="icon && iconType === 'nuxt-icon'" :name="`df:${iconDisplay}`" class="icon text-[24px]" />
      <slot name="icon"></slot>
      <span v-if="text" class="text text-level-3 font-semibold">{{ text }}</span>
    </div>
  </df-btn>
</template>

<script setup lang="ts">
import { type DfBtnShape, type DfBtnSize } from '@/components/df/btn/types';

type Props = {
  shape?: DfBtnShape | undefined;
  size?: DfBtnSize;
  active?: boolean;
  icon?: string;
  activeIcon?: string;
  text?: string | number;
  disabled?: boolean;
  iconType?: 'icon-font' | 'nuxt-icon';
};

const props = withDefaults(defineProps<Props>(), {
  shape: undefined,
  size: 'middle',
  icon: undefined,
  text: undefined,
  active: false,
  disabled: false,
  activeIcon: undefined,
  iconType: 'icon-font',
});

const iconDisplay = computed(() => {
  return props.active ? props.activeIcon || props.icon : props.icon;
});
</script>

<style lang="less" scoped>
.action-button {
  &.done {
    background-color: theme('colors.red.liked');
    border-color: transparent;
    .content {
      .icon {
        color: theme('colors.red.default');
      }
    }
  }
  &.done:hover {
    background-color: theme('colors.red.liked-hover');
  }
}

// 移动端适配 start
@media screen and (max-width: 767px) {
  // .action-button {
  //   max-width: 56px;
  //   max-height: 56px;
  // }
}
</style>
